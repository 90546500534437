import React from 'react';
import { isMobile } from 'react-device-detect';

import repeatable from '../assets/Repeatable.png';

interface Color {
  color: string;
}

export const Ditto = () => isMobile ? (
  <svg width="67.535" height="72" viewBox="0 0 67.535 72">
    <g id="Ditto" transform="translate(-16.221 0.001)">
      <g id="Group_503" data-name="Group 503" transform="translate(16.221 -0.001)">
        <path id="Path_97" data-name="Path 97" d="M52.044,4.886a1.17,1.17,0,0,1-.827-2L53.763.342A1.17,1.17,0,0,1,55.418,2L52.872,4.543A1.166,1.166,0,0,1,52.044,4.886Zm0,0" transform="translate(-44.933 0.001)" fill="#e2e2e2"/>
        <path id="Path_98" data-name="Path 98" d="M17.391,39.541a1.17,1.17,0,0,1-.828-2L19.11,35a1.17,1.17,0,0,1,1.655,1.655L18.218,39.2A1.166,1.166,0,0,1,17.391,39.541Zm0,0" transform="translate(-16.221 -28.713)" fill="#e2e2e2"/>
        <path id="Path_99" data-name="Path 99" d="M19.937,4.886a1.163,1.163,0,0,1-.827-.343L16.563,2A1.17,1.17,0,0,1,18.218.342l2.547,2.546a1.17,1.17,0,0,1-.828,2Zm0,0" transform="translate(-16.221 0.001)" fill="#e2e2e2"/>
        <path id="Path_100" data-name="Path 100" d="M54.591,39.541a1.168,1.168,0,0,1-.828-.343l-2.546-2.547A1.17,1.17,0,0,1,52.872,35l2.546,2.547a1.17,1.17,0,0,1-.827,2Zm0,0" transform="translate(-44.933 -28.713)" fill="#e2e2e2"/>
        <path id="Path_101" data-name="Path 101" d="M343.055,45.626a3.61,3.61,0,1,1,3.611-3.609A3.614,3.614,0,0,1,343.055,45.626Zm0-4.879a1.269,1.269,0,1,0,1.27,1.27A1.271,1.271,0,0,0,343.055,40.747Zm0,0" transform="translate(-283.397 -31.822)" fill="#e2e2e2"/>
        <path id="Path_102" data-name="Path 102" d="M293.019,17.706a1.578,1.578,0,1,1-1.578-1.577A1.578,1.578,0,0,1,293.019,17.706Zm0,0" transform="translate(-242.45 -13.364)" fill="#e2e2e2"/>
        <path id="Path_103" data-name="Path 103" d="M200.91,42.717a1.17,1.17,0,0,1-.828-2l3.451-3.449a1.17,1.17,0,0,1,1.655,1.655l-3.451,3.449A1.166,1.166,0,0,1,200.91,42.717Zm0,0" transform="translate(-167.926 -30.597)" fill="#e2e2e2"/>
        <path id="Path_104" data-name="Path 104" d="M204.361,42.717a1.166,1.166,0,0,1-.827-.343l-3.451-3.449a1.17,1.17,0,0,1,1.655-1.655l3.451,3.449a1.17,1.17,0,0,1-.828,2Zm0,0" transform="translate(-167.926 -30.597)" fill="#e2e2e2"/>
      </g>
      <path id="Path_105" data-name="Path 105" d="M81.111,158.6a76.508,76.508,0,0,1-25.711.915c-17.654-2.557-19.746-4.779-20.628-7.983a12.6,12.6,0,0,1,.078-6.834,77.592,77.592,0,0,1,3.228-8.762,19.771,19.771,0,0,0,1.156-11.986l-2.209-10.116a3.974,3.974,0,0,1,2.869-4.677A4.044,4.044,0,0,1,43.9,110.3l2.938,3.151a2.717,2.717,0,0,0,3.966,0l2.869-3.076a5.276,5.276,0,0,1,4.259-1.656l2.146.163a5.262,5.262,0,0,1,3.868,2.16,3,3,0,0,0,3.443,1.057l1.276-.459a8.1,8.1,0,0,1,5.275-.07,8.013,8.013,0,0,1,3.769,2.626l2.364,2.978a5.98,5.98,0,0,0,5.655,2.17l2.409-.4a6.667,6.667,0,0,1,6.4,2.537,6.515,6.515,0,0,1,.57,6.988L92.53,133.34a9.031,9.031,0,0,0,1.082,10.054l2.755,3.273a5.224,5.224,0,0,1-2.2,8.274c-.9.332-1.954.7-3.155,1.09" transform="translate(-15.011 -89.39)" fill="#a288e3"/>
      <path id="Path_106" data-name="Path 106" d="M59.633,155.728a69.964,69.964,0,0,1-10.06-.713c-17.181-2.489-20.436-4.642-21.591-8.832a13.747,13.747,0,0,1,.082-7.463,79.982,79.982,0,0,1,3.276-8.9,18.528,18.528,0,0,0,1.088-11.284l-2.209-10.116a5.146,5.146,0,0,1,3.712-6.053,5.208,5.208,0,0,1,5.187,1.475L42.055,107a1.526,1.526,0,0,0,2.227,0l2.869-3.078a6.425,6.425,0,0,1,5.216-2.029l2.145.163A6.426,6.426,0,0,1,59.25,104.7a1.813,1.813,0,0,0,2.076.638l1.275-.459a9.321,9.321,0,0,1,6.051-.08,9.177,9.177,0,0,1,4.322,3.011l2.363,2.979a4.771,4.771,0,0,0,4.53,1.739l2.406-.4a7.815,7.815,0,0,1,7.537,2.991,7.714,7.714,0,0,1,.671,8.232l-2.575,4.868a7.869,7.869,0,0,0,.943,8.761l2.754,3.273a6.392,6.392,0,0,1-2.7,10.121c-.911.337-1.983.715-3.2,1.108a1.186,1.186,0,0,1-1.49-.751,1.169,1.169,0,0,1,.758-1.476c1.182-.382,2.224-.747,3.107-1.073a4.062,4.062,0,0,0,1.707-6.429l-2.756-3.274a10.2,10.2,0,0,1-1.22-11.347l2.575-4.867a5.381,5.381,0,0,0-.467-5.743,5.451,5.451,0,0,0-5.257-2.086l-2.407.4a7.161,7.161,0,0,1-6.776-2.6l-2.364-2.979A6.821,6.821,0,0,0,67.9,107.02a6.93,6.93,0,0,0-4.5.06l-1.275.459a4.2,4.2,0,0,1-4.807-1.477,4.067,4.067,0,0,0-3-1.673l-2.146-.163a4.077,4.077,0,0,0-3.3,1.282l-2.869,3.078a3.9,3.9,0,0,1-2.848,1.236h0a3.906,3.906,0,0,1-2.85-1.236l-2.938-3.151a2.839,2.839,0,0,0-2.827-.8,2.806,2.806,0,0,0-2.025,3.3l2.209,10.116a20.839,20.839,0,0,1-1.222,12.688,77.63,77.63,0,0,0-3.179,8.628,11.424,11.424,0,0,0-.075,6.2c.72,2.615,2.552,4.656,19.652,7.134a74.608,74.608,0,0,0,25.3-.905,1.171,1.171,0,1,1,.466,2.295A82,82,0,0,1,59.633,155.728Zm0,0" transform="translate(-9.35 -83.729)" fill="#020202"/>
      <path id="Path_107" data-name="Path 107" d="M333.666,390.6q-.875.247-1.815.492" transform="translate(-260.884 -322.961)" fill="#bd53b5"/>
      <path id="Path_108" data-name="Path 108" d="M326.194,386.607a1.17,1.17,0,0,1-.292-2.3q.925-.24,1.791-.485a1.171,1.171,0,0,1,.636,2.254q-.888.248-1.839.5A1.149,1.149,0,0,1,326.194,386.607Zm0,0" transform="translate(-255.228 -317.306)" fill="#020202"/>
      <path id="Path_109" data-name="Path 109" d="M249.9,178.37a1.085,1.085,0,1,1-1.085-1.085A1.085,1.085,0,0,1,249.9,178.37Zm0,0" transform="translate(-191.434 -146.595)" fill="#020202"/>
      <path id="Path_110" data-name="Path 110" d="M170.791,168.874a1.085,1.085,0,1,1-1.085-1.085A1.085,1.085,0,0,1,170.791,168.874Zm0,0" transform="translate(-126.017 -138.743)" fill="#020202"/>
      <path id="Path_111" data-name="Path 111" d="M154.569,194.028a82.869,82.869,0,0,1-20.4-2.285,1.171,1.171,0,1,1,.627-2.256,85.51,85.51,0,0,0,23.512,2.128,1.17,1.17,0,1,1,.088,2.338C157.081,194,155.8,194.028,154.569,194.028Zm0,0" transform="translate(-96.702 -156.638)" fill="#020202"/>
    </g>
  </svg>
) : (
  <svg width="112.559" height="120.001" viewBox="0 0 112.559 120.001">
    <g id="Ditto" transform="translate(-16.221 0.001)">
      <g id="Group_503" data-name="Group 503" transform="translate(16.221 -0.001)">
        <path id="Path_97" data-name="Path 97" d="M52.806,8.069a1.933,1.933,0,0,1-1.366-3.3l4.2-4.2A1.932,1.932,0,0,1,58.378,3.3l-4.2,4.2A1.926,1.926,0,0,1,52.806,8.069Zm0,0" transform="translate(-41.063 0.001)" fill="#e2e2e2"/>
        <path id="Path_98" data-name="Path 98" d="M18.153,42.725a1.933,1.933,0,0,1-1.367-3.3l4.206-4.206a1.932,1.932,0,1,1,2.733,2.733l-4.206,4.206A1.926,1.926,0,0,1,18.153,42.725Zm0,0" transform="translate(-16.221 -24.843)" fill="#e2e2e2"/>
        <path id="Path_99" data-name="Path 99" d="M22.358,8.069A1.921,1.921,0,0,1,20.992,7.5L16.786,3.3A1.932,1.932,0,0,1,19.519.566l4.206,4.2a1.933,1.933,0,0,1-1.367,3.3Zm0,0" transform="translate(-16.221 0.001)" fill="#e2e2e2"/>
        <path id="Path_100" data-name="Path 100" d="M57.012,42.725a1.929,1.929,0,0,1-1.367-.566l-4.2-4.206a1.932,1.932,0,1,1,2.733-2.733l4.2,4.206a1.933,1.933,0,0,1-1.366,3.3Zm0,0" transform="translate(-41.063 -24.843)" fill="#e2e2e2"/>
        <path id="Path_101" data-name="Path 101" d="M345.407,50.329a5.962,5.962,0,1,1,5.963-5.961A5.968,5.968,0,0,1,345.407,50.329Zm0-8.058a2.1,2.1,0,1,0,2.1,2.1A2.1,2.1,0,0,0,345.407,42.271Zm0,0" transform="translate(-245.856 -27.532)" fill="#e2e2e2"/>
        <path id="Path_102" data-name="Path 102" d="M295.074,18.733a2.606,2.606,0,1,1-2.606-2.6A2.606,2.606,0,0,1,295.074,18.733Zm0,0" transform="translate(-210.751 -11.562)" fill="#e2e2e2"/>
        <path id="Path_103" data-name="Path 103" d="M201.673,46.489a1.933,1.933,0,0,1-1.367-3.3l5.7-5.7a1.932,1.932,0,0,1,2.733,2.733l-5.7,5.7A1.926,1.926,0,0,1,201.673,46.489Zm0,0" transform="translate(-146.632 -26.473)" fill="#e2e2e2"/>
        <path id="Path_104" data-name="Path 104" d="M207.371,46.489A1.926,1.926,0,0,1,206,45.923l-5.7-5.7a1.932,1.932,0,0,1,2.733-2.733l5.7,5.7a1.933,1.933,0,0,1-1.367,3.3Zm0,0" transform="translate(-146.632 -26.473)" fill="#e2e2e2"/>
      </g>
      <path id="Path_105" data-name="Path 105" d="M112.342,191.113a128.822,128.822,0,0,1-42.878,1.512c-29.441-4.224-32.93-7.893-34.4-13.184a20.608,20.608,0,0,1,.13-11.286,127.337,127.337,0,0,1,5.384-14.47,32.359,32.359,0,0,0,1.928-19.8l-3.685-16.706A6.559,6.559,0,0,1,43.6,109.46a6.788,6.788,0,0,1,6.685,1.881l4.9,5.2a4.561,4.561,0,0,0,6.614,0l4.785-5.081a8.846,8.846,0,0,1,7.1-2.735l3.579.27a8.8,8.8,0,0,1,6.451,3.568,5.025,5.025,0,0,0,5.741,1.746l2.128-.759a13.635,13.635,0,0,1,8.8-.116,13.364,13.364,0,0,1,6.285,4.336l3.942,4.918a10.023,10.023,0,0,0,9.431,3.584l4.017-.656a11.173,11.173,0,0,1,10.67,4.19,10.673,10.673,0,0,1,.95,11.54l-4.3,8.039a14.8,14.8,0,0,0,1.8,16.6l4.595,5.406a8.564,8.564,0,0,1,2.051,5.552,8.664,8.664,0,0,1-5.723,8.113c-1.495.549-3.259,1.161-5.262,1.8" transform="translate(-12.988 -75.712)" fill="#a288e3"/>
      <path id="Path_106" data-name="Path 106" d="M81.067,190.815A117.721,117.721,0,0,1,64.3,189.638c-28.643-4.111-34.069-7.666-36-14.586a22.5,22.5,0,0,1,.136-12.326A131.293,131.293,0,0,1,33.9,148.036,30.333,30.333,0,0,0,35.711,129.4l-3.682-16.707a8.494,8.494,0,0,1,6.189-10,8.735,8.735,0,0,1,8.647,2.435l4.9,5.2a2.56,2.56,0,0,0,3.713,0l4.783-5.083a10.766,10.766,0,0,1,8.7-3.351l3.576.27a10.738,10.738,0,0,1,7.9,4.372,3.039,3.039,0,0,0,3.461,1.053l2.125-.759a15.678,15.678,0,0,1,10.088-.132,15.3,15.3,0,0,1,7.206,4.973l3.94,4.919a7.992,7.992,0,0,0,7.552,2.872l4.011-.656a13.089,13.089,0,0,1,12.564,4.939,12.641,12.641,0,0,1,1.118,13.6L128.2,145.39a12.9,12.9,0,0,0,1.573,14.469l4.592,5.406a10.508,10.508,0,0,1,2.5,6.789,10.642,10.642,0,0,1-7,9.925c-1.519.556-3.307,1.18-5.338,1.829a1.981,1.981,0,0,1-2.484-1.24,1.928,1.928,0,0,1,1.264-2.437c1.971-.63,3.708-1.234,5.179-1.772a6.757,6.757,0,0,0,4.437-6.3,6.669,6.669,0,0,0-1.591-4.312l-4.6-5.407a16.923,16.923,0,0,1-3.673-7.536,16.751,16.751,0,0,1,1.639-11.2L129,135.558a8.818,8.818,0,0,0-.779-9.484,9.129,9.129,0,0,0-8.764-3.445l-4.012.657a12,12,0,0,1-11.3-4.3l-3.941-4.919a11.373,11.373,0,0,0-5.354-3.7,11.657,11.657,0,0,0-7.495.1l-2.125.759a7.033,7.033,0,0,1-8.014-2.439,6.8,6.8,0,0,0-4.993-2.764l-3.578-.27a6.831,6.831,0,0,0-5.5,2.118l-4.783,5.083A6.532,6.532,0,0,1,53.621,115h0a6.541,6.541,0,0,1-4.752-2.04l-4.9-5.2a4.763,4.763,0,0,0-4.713-1.329,4.631,4.631,0,0,0-3.376,5.451l3.683,16.707a34.116,34.116,0,0,1-2.037,20.954,127.431,127.431,0,0,0-5.3,14.249,18.7,18.7,0,0,0-.125,10.245c1.2,4.319,4.255,7.69,32.763,11.781a125.537,125.537,0,0,0,42.172-1.5,1.967,1.967,0,0,1,2.32,1.514,1.934,1.934,0,0,1-1.543,2.276A137.95,137.95,0,0,1,81.067,190.815Zm0,0" transform="translate(-8.09 -70.815)" fill="#020202"/>
      <path id="Path_107" data-name="Path 107" d="M334.849,390.6q-1.445.408-3,.812" transform="translate(-224.192 -277.8)" fill="#bd53b5"/>
      <path id="Path_108" data-name="Path 108" d="M326.955,388.452a1.933,1.933,0,0,1-.482-3.8q1.528-.4,2.957-.8a1.934,1.934,0,0,1,1.05,3.723q-1.466.41-3.037.821A1.9,1.9,0,0,1,326.955,388.452Zm0,0" transform="translate(-219.298 -272.907)" fill="#020202"/>
      <path id="Path_109" data-name="Path 109" d="M251.318,179.077a1.792,1.792,0,1,1-1.792-1.792A1.792,1.792,0,0,1,251.318,179.077Zm0,0" transform="translate(-164.694 -126.119)" fill="#020202"/>
      <path id="Path_110" data-name="Path 110" d="M172.2,169.581a1.792,1.792,0,1,1-1.792-1.792A1.791,1.791,0,0,1,172.2,169.581Zm0,0" transform="translate(-108.414 -119.364)" fill="#020202"/>
      <path id="Path_111" data-name="Path 111" d="M168.419,197.014c-19.858,0-33.086-3.605-33.693-3.773a1.933,1.933,0,1,1,1.035-3.726c.156.043,16.141,4.385,38.83,3.515a1.932,1.932,0,1,1,.146,3.861C172.568,196.976,170.458,197.014,168.419,197.014Zm0,0" transform="translate(-82.914 -134.732)" fill="#020202"/>
    </g>
  </svg>
);

export const Phanpy = () => isMobile ? (
  <svg width="76.792" height="72" viewBox="0 0 76.792 72">
    <g id="Phanpy" transform="translate(-0.001 -0.406)">
      <g id="Group_502" data-name="Group 502" transform="translate(1.143 3.174)">
        <path id="Path_66" data-name="Path 66" d="M345.594,20.488a1.228,1.228,0,0,1-.868-2.1l2.241-2.241a1.228,1.228,0,1,1,1.737,1.737l-2.241,2.241A1.221,1.221,0,0,1,345.594,20.488Zm0,0" transform="translate(-283.55 -15.791)" fill="#f4f4f4"/>
        <path id="Path_67" data-name="Path 67" d="M316.543,49.54a1.228,1.228,0,0,1-.869-2.1l2.241-2.241a1.228,1.228,0,1,1,1.737,1.737l-2.241,2.241A1.221,1.221,0,0,1,316.543,49.54Zm0,0" transform="translate(-259.725 -39.616)" fill="#f4f4f4"/>
        <path id="Path_68" data-name="Path 68" d="M318.783,20.488a1.222,1.222,0,0,1-.869-.36l-2.241-2.241a1.228,1.228,0,1,1,1.737-1.737l2.241,2.241a1.228,1.228,0,0,1-.868,2.1Zm0,0" transform="translate(-259.725 -15.791)" fill="#f4f4f4"/>
        <path id="Path_69" data-name="Path 69" d="M347.835,49.54a1.224,1.224,0,0,1-.868-.36l-2.241-2.241a1.228,1.228,0,0,1,1.737-1.737l2.241,2.241a1.228,1.228,0,0,1-.869,2.1Zm0,0" transform="translate(-283.55 -39.616)" fill="#f4f4f4"/>
        <path id="Path_70" data-name="Path 70" d="M51.83,134.574H48.661a1.228,1.228,0,0,1,0-2.456H51.83a1.228,1.228,0,0,1,0,2.456Zm0,0" transform="translate(-40.041 -111.188)" fill="#f4f4f4"/>
        <path id="Path_71" data-name="Path 71" d="M10.743,134.574H7.575a1.228,1.228,0,0,1,0-2.456h3.168a1.228,1.228,0,1,1,0,2.456Zm0,0" transform="translate(-6.348 -111.188)" fill="#f4f4f4"/>
        <path id="Path_72" data-name="Path 72" d="M36.92,108.393a1.227,1.227,0,0,1-1.229-1.228V104a1.229,1.229,0,0,1,2.457,0v3.168A1.227,1.227,0,0,1,36.92,108.393Zm0,0" transform="translate(-30.412 -87.12)" fill="#f4f4f4"/>
        <path id="Path_73" data-name="Path 73" d="M36.92,149.483a1.228,1.228,0,0,1-1.229-1.229v-3.168a1.229,1.229,0,0,1,2.457,0v3.168A1.228,1.228,0,0,1,36.92,149.483Zm0,0" transform="translate(-30.412 -120.817)" fill="#f4f4f4"/>
        <path id="Path_74" data-name="Path 74" d="M331.129,126.038a3.789,3.789,0,1,1,3.79-3.787A3.793,3.793,0,0,1,331.129,126.038Zm0-5.12a1.332,1.332,0,1,0,1.333,1.333A1.333,1.333,0,0,0,331.129,120.918Zm0,0" transform="translate(-269.587 -99.988)" fill="#f4f4f4"/>
        <path id="Path_75" data-name="Path 75" d="M141.074,41.141a1.228,1.228,0,0,1-.869-2.1l3.622-3.62a1.228,1.228,0,0,1,1.737,1.737l-3.621,3.62A1.227,1.227,0,0,1,141.074,41.141Zm0,0" transform="translate(-115.827 -31.597)" fill="#f4f4f4"/>
      </g>
      <path id="Path_76" data-name="Path 76" d="M24.51,191.207S7.925,197.841,6.849,213.3a7.145,7.145,0,0,0,13.578,3.479c1.323-2.82,2.6-7.323,2.929-14.408.144-3.079.439-6.157.876-9.208Zm0,0" transform="translate(-5.602 -156.472)" fill="#a288e3"/>
      <path id="Path_77" data-name="Path 77" d="M8.393,216.47a8.6,8.6,0,0,1-.937-.051,8.3,8.3,0,0,1-7.435-8.8c1.117-16.04,17.724-22.863,18.431-23.147a1.228,1.228,0,0,1,1.672,1.314l-.278,1.954c-.433,3.028-.724,6.087-.864,9.092-.289,6.178-1.314,11.183-3.045,14.873A8.319,8.319,0,0,1,8.393,216.47Zm8.975-28.752c-4.3,2.293-14.106,8.73-14.9,20.068a5.85,5.85,0,0,0,5.255,6.19,5.856,5.856,0,0,0,5.985-3.318c1.594-3.4,2.541-8.088,2.814-13.944C16.666,193.742,16.949,190.716,17.368,187.718Zm0,0" transform="translate(0 -150.875)" fill="#101010"/>
      <path id="Path_78" data-name="Path 78" d="M12.72,286.434s-2.677-.379-5.519,2.282a21.867,21.867,0,0,0-.353,2.66,7.148,7.148,0,0,0,9.661,7.079C13.922,297.182,10.646,294.039,12.72,286.434Zm0,0" transform="translate(-5.602 -234.55)" fill="#de3c4b"/>
      <path id="Path_79" data-name="Path 79" d="M8.374,294.564a8.515,8.515,0,0,1-.919-.05,8.3,8.3,0,0,1-7.434-8.8A23.248,23.248,0,0,1,.394,282.9a1.227,1.227,0,0,1,.366-.661,8.463,8.463,0,0,1,6.531-2.6A1.227,1.227,0,0,1,8.3,281.178c-1.422,5.219-.335,8.884,3.148,10.6a1.229,1.229,0,0,1-.111,2.253A8.488,8.488,0,0,1,8.374,294.564ZM2.732,283.772a20.131,20.131,0,0,0-.259,2.111,5.849,5.849,0,0,0,5.255,6.19h0c-1.76-1.831-3.137-4.869-2.194-9.842a7.1,7.1,0,0,0-2.806,1.541Zm0,0" transform="translate(0 -228.971)" fill="#101010"/>
      <path id="Path_80" data-name="Path 80" d="M307.633,191.207s16.585,6.634,17.661,22.091a7.145,7.145,0,0,1-13.578,3.479c-1.322-2.82-2.6-7.323-2.929-14.408-.144-3.079-.439-6.157-.875-9.208Zm0,0" transform="translate(-252.282 -156.472)" fill="#a288e3"/>
      <path id="Path_81" data-name="Path 81" d="M312.551,216.471a8.318,8.318,0,0,1-7.544-4.768c-1.73-3.69-2.755-8.694-3.044-14.872-.141-3-.432-6.063-.864-9.091l-.278-1.955a1.228,1.228,0,0,1,1.672-1.314c.706.283,17.315,7.105,18.431,23.146a8.3,8.3,0,0,1-7.435,8.8A8.6,8.6,0,0,1,312.551,216.471Zm-8.975-28.751c.419,3,.7,6.022.841,9,.274,5.855,1.22,10.546,2.814,13.943a5.916,5.916,0,0,0,11.24-2.871C317.682,196.449,307.876,190.012,303.576,187.719Zm0,0" transform="translate(-246.685 -150.876)" fill="#101010"/>
      <path id="Path_82" data-name="Path 82" d="M355.887,286.434s2.677-.379,5.518,2.282a21.857,21.857,0,0,1,.353,2.66,7.148,7.148,0,0,1-9.661,7.079C354.685,297.182,357.961,294.039,355.887,286.434Zm0,0" transform="translate(-288.747 -234.55)" fill="#de3c4b"/>
      <path id="Path_83" data-name="Path 83" d="M349.028,294.564a8.493,8.493,0,0,1-2.966-.537,1.229,1.229,0,0,1-.111-2.253c3.482-1.713,4.57-5.377,3.148-10.6a1.227,1.227,0,0,1,1.012-1.539c.136-.015,3.3-.427,6.531,2.6a1.226,1.226,0,0,1,.365.661,23.114,23.114,0,0,1,.373,2.811,8.3,8.3,0,0,1-7.435,8.8A8.149,8.149,0,0,1,349.028,294.564Zm2.835-12.333c.943,4.973-.434,8.011-2.193,9.842h0a5.85,5.85,0,0,0,5.256-6.19,20.519,20.519,0,0,0-.259-2.111A7.1,7.1,0,0,0,351.863,282.231Zm0,0" transform="translate(-283.144 -228.971)" fill="#101010"/>
      <path id="Path_84" data-name="Path 84" d="M144.695,41.141a1.224,1.224,0,0,1-.868-.36l-3.622-3.62a1.228,1.228,0,0,1,1.737-1.737l3.621,3.62a1.228,1.228,0,0,1-.869,2.1Zm0,0" transform="translate(-114.684 -28.422)" fill="#f4f4f4"/>
      <path id="Path_85" data-name="Path 85" d="M86.728,180.776a22.527,22.527,0,1,1,7.283,5.552" transform="translate(-66.611 -117.361)" fill="#a288e3"/>
      <path id="Path_86" data-name="Path 86" d="M98.111,184.148a23.69,23.69,0,0,1-10.254-2.333A1.228,1.228,0,0,1,88.92,179.6a21.217,21.217,0,1,0-6.888-5.249,1.228,1.228,0,0,1-1.854,1.609,23.737,23.737,0,1,1,17.933,8.186Zm0,0" transform="translate(-60.988 -111.741)" fill="#101010"/>
      <path id="Path_87" data-name="Path 87" d="M121.1,360.623a1.216,1.216,0,0,1-.7-.221,23.875,23.875,0,0,1-1.913-1.478,1.228,1.228,0,1,1,1.6-1.864,21.507,21.507,0,0,0,1.716,1.326,1.229,1.229,0,0,1-.7,2.236Zm0,0" transform="translate(-96.813 -292.241)" fill="#101010"/>
      <path id="Path_88" data-name="Path 88" d="M250.41,256.343a1.228,1.228,0,0,1-1.229-1.229,1.56,1.56,0,1,0-3.12,0,1.228,1.228,0,1,1-2.456,0,4.016,4.016,0,1,1,8.033,0A1.227,1.227,0,0,1,250.41,256.343Zm0,0" transform="translate(-199.775 -205.587)" fill="#101010"/>
      <path id="Path_89" data-name="Path 89" d="M125.312,256.343a1.228,1.228,0,0,1-1.229-1.229,1.56,1.56,0,1,0-3.12,0,1.228,1.228,0,1,1-2.456,0,4.016,4.016,0,1,1,8.032,0A1.227,1.227,0,0,1,125.312,256.343Zm0,0" transform="translate(-97.185 -205.587)" fill="#101010"/>
      <path id="Path_90" data-name="Path 90" d="M23.216,1.995A1.588,1.588,0,1,1,21.627.406,1.588,1.588,0,0,1,23.216,1.995Zm0,0" transform="translate(-16.433)" fill="#f4f4f4"/>
      <path id="Path_91" data-name="Path 91" d="M412.333,165.834a1.588,1.588,0,1,1-1.588-1.588A1.588,1.588,0,0,1,412.333,165.834Zm0,0" transform="translate(-335.539 -134.362)" fill="#f4f4f4"/>
      <path id="Path_92" data-name="Path 92" d="M179.665,295.161c-5.244-2.322-10.272-2.574-15.005,0v-6.431c4.925-2.882,9.929-2.8,15.005,0Zm0,0" transform="translate(-135.033 -234.702)" fill="#de3c4b"/>
      <path id="Path_93" data-name="Path 93" d="M174.066,290.79a1.222,1.222,0,0,1-.5-.105c-5.2-2.3-9.748-2.313-13.923-.044a1.229,1.229,0,0,1-1.815-1.079v-6.43a1.229,1.229,0,0,1,.608-1.061c5.227-3.057,10.683-3.064,16.218-.015a1.228,1.228,0,0,1,.636,1.076v6.43a1.23,1.23,0,0,1-1.229,1.228Zm-7.89-4.284a19.648,19.648,0,0,1,6.661,1.24v-3.874a12.838,12.838,0,0,0-12.549-.019v3.778A15.784,15.784,0,0,1,166.176,286.506Zm0,0" transform="translate(-129.434 -229.103)" fill="#101010"/>
      <path id="Path_94" data-name="Path 94" d="M159.061,338.943a1.228,1.228,0,0,1-1.229-1.229v-9.135a1.228,1.228,0,0,1,2.456,0v9.135A1.227,1.227,0,0,1,159.061,338.943Zm0,0" transform="translate(-129.434 -268.121)" fill="#101010"/>
      <path id="Path_95" data-name="Path 95" d="M242.462,338.995a1.227,1.227,0,0,1-1.228-1.229v-9.19a1.228,1.228,0,1,1,2.456,0v9.19A1.228,1.228,0,0,1,242.462,338.995Zm0,0" transform="translate(-197.83 -268.118)" fill="#101010"/>
    </g>
  </svg>
) : (
  <svg width="127.987" height="120" viewBox="0 0 127.987 120">
    <g id="PhanpyDesktop" transform="translate(-0.001 -0.406)">
      <g id="Group_502" data-name="Group 502" transform="translate(1.904 5.02)">
        <path id="Path_66" data-name="Path 66" d="M346.413,23.619a2.047,2.047,0,0,1-1.447-3.494L348.7,16.39a2.047,2.047,0,0,1,2.894,2.894l-3.734,3.734A2.036,2.036,0,0,1,346.413,23.619Zm0,0" transform="translate(-243.005 -15.791)" fill="#f4f4f4"/>
        <path id="Path_67" data-name="Path 67" d="M317.361,52.671a2.047,2.047,0,0,1-1.448-3.494l3.734-3.734a2.047,2.047,0,0,1,2.894,2.894l-3.734,3.734A2.035,2.035,0,0,1,317.361,52.671Zm0,0" transform="translate(-222.665 -36.131)" fill="#f4f4f4"/>
        <path id="Path_68" data-name="Path 68" d="M321.1,23.619a2.038,2.038,0,0,1-1.448-.6l-3.734-3.734a2.047,2.047,0,1,1,2.894-2.894l3.734,3.734a2.047,2.047,0,0,1-1.447,3.494Zm0,0" transform="translate(-222.665 -15.791)" fill="#f4f4f4"/>
        <path id="Path_69" data-name="Path 69" d="M350.147,52.671a2.04,2.04,0,0,1-1.447-.6l-3.734-3.734a2.047,2.047,0,1,1,2.894-2.894l3.734,3.734a2.047,2.047,0,0,1-1.448,3.494Zm0,0" transform="translate(-243.005 -36.131)" fill="#f4f4f4"/>
        <path id="Path_70" data-name="Path 70" d="M54.76,136.211H49.48a2.047,2.047,0,0,1,0-4.094h5.28a2.047,2.047,0,0,1,0,4.094Zm0,0" transform="translate(-35.113 -97.235)" fill="#f4f4f4"/>
        <path id="Path_71" data-name="Path 71" d="M13.673,136.211H8.394a2.047,2.047,0,0,1,0-4.094h5.279a2.047,2.047,0,1,1,0,4.094Zm0,0" transform="translate(-6.348 -97.235)" fill="#f4f4f4"/>
        <path id="Path_72" data-name="Path 72" d="M37.739,112.143a2.046,2.046,0,0,1-2.048-2.046v-5.279a2.048,2.048,0,1,1,4.1,0V110.1A2.046,2.046,0,0,1,37.739,112.143Zm0,0" transform="translate(-26.892 -76.687)" fill="#f4f4f4"/>
        <path id="Path_73" data-name="Path 73" d="M37.739,153.233a2.047,2.047,0,0,1-2.048-2.048v-5.279a2.048,2.048,0,0,1,4.1,0v5.279A2.047,2.047,0,0,1,37.739,153.233Zm0,0" transform="translate(-26.892 -105.456)" fill="#f4f4f4"/>
        <path id="Path_74" data-name="Path 74" d="M333.655,131.089a6.314,6.314,0,1,1,6.316-6.312A6.321,6.321,0,0,1,333.655,131.089Zm0-8.533a2.22,2.22,0,1,0,2.222,2.221A2.222,2.222,0,0,0,333.655,122.556Zm0,0" transform="translate(-231.084 -87.674)" fill="#f4f4f4"/>
        <path id="Path_75" data-name="Path 75" d="M141.893,45.191a2.047,2.047,0,0,1-1.448-3.494l6.036-6.034a2.047,2.047,0,0,1,2.894,2.894l-6.035,6.034A2.045,2.045,0,0,1,141.893,45.191Zm0,0" transform="translate(-99.814 -29.285)" fill="#f4f4f4"/>
      </g>
      <path id="Path_76" data-name="Path 76" d="M36.3,191.207S8.653,202.264,6.86,228.025a11.908,11.908,0,0,0,22.631,5.8c2.2-4.7,4.331-12.206,4.881-24.013.24-5.132.732-10.261,1.46-15.346Zm0,0" transform="translate(-4.782 -133.586)" fill="#a288e3"/>
      <path id="Path_77" data-name="Path 77" d="M13.988,237.861a14.331,14.331,0,0,1-1.561-.085A13.835,13.835,0,0,1,.035,223.106C1.9,196.372,29.575,185,30.753,184.528a2.047,2.047,0,0,1,2.787,2.189l-.464,3.256c-.722,5.047-1.206,10.145-1.441,15.153-.481,10.3-2.189,18.639-5.074,24.788A13.865,13.865,0,0,1,13.988,237.861Zm14.958-47.919c-7.168,3.821-23.509,14.551-24.826,33.447a9.749,9.749,0,0,0,8.758,10.316,9.76,9.76,0,0,0,9.975-5.53c2.657-5.661,4.234-13.48,4.69-23.24C27.776,199.981,28.248,194.938,28.946,189.942Zm0,0" transform="translate(0 -128.808)" fill="#101010"/>
      <path id="Path_78" data-name="Path 78" d="M16.646,286.447s-4.462-.633-9.2,3.8a36.447,36.447,0,0,0-.588,4.434,11.914,11.914,0,0,0,16.1,11.8C18.649,304.359,13.189,299.121,16.646,286.447Zm0,0" transform="translate(-4.782 -200.244)" fill="#de3c4b"/>
      <path id="Path_79" data-name="Path 79" d="M13.956,304.532a14.2,14.2,0,0,1-1.531-.083A13.835,13.835,0,0,1,.035,289.779a38.748,38.748,0,0,1,.621-4.684,2.045,2.045,0,0,1,.61-1.1c5.387-5.047,10.665-4.362,10.885-4.336a2.045,2.045,0,0,1,1.687,2.565c-2.371,8.7-.558,14.806,5.247,17.661a2.048,2.048,0,0,1-.185,3.754A14.147,14.147,0,0,1,13.956,304.532Zm-9.4-17.987a33.547,33.547,0,0,0-.432,3.518,9.749,9.749,0,0,0,8.758,10.316l.008,0c-2.933-3.051-5.228-8.115-3.657-16.4a11.839,11.839,0,0,0-4.677,2.568Zm0,0" transform="translate(0 -195.481)" fill="#101010"/>
      <path id="Path_80" data-name="Path 80" d="M307.633,191.207s27.642,11.056,29.435,36.818a11.908,11.908,0,0,1-22.631,5.8c-2.2-4.7-4.331-12.206-4.881-24.013-.24-5.132-.732-10.261-1.458-15.346Zm0,0" transform="translate(-215.383 -133.586)" fill="#a288e3"/>
      <path id="Path_81" data-name="Path 81" d="M320.379,237.862a13.864,13.864,0,0,1-12.573-7.947c-2.884-6.15-4.592-14.49-5.073-24.787-.234-5.008-.719-10.105-1.441-15.152l-.464-3.258a2.047,2.047,0,0,1,2.787-2.189c1.177.472,28.858,11.842,30.718,38.576a13.834,13.834,0,0,1-12.392,14.67A14.334,14.334,0,0,1,320.379,237.862Zm-14.958-47.919c.7,5,1.169,10.036,1.4,14.995.457,9.759,2.034,17.577,4.69,23.239a9.86,9.86,0,0,0,18.734-4.785C328.931,204.492,312.588,193.764,305.421,189.943Zm0,0" transform="translate(-210.604 -128.809)" fill="#101010"/>
      <path id="Path_82" data-name="Path 82" d="M358.414,286.447s4.462-.633,9.2,3.8a36.446,36.446,0,0,1,.588,4.434,11.914,11.914,0,0,1-16.1,11.8C356.411,304.359,361.87,299.121,358.414,286.447Zm0,0" transform="translate(-246.514 -200.244)" fill="#de3c4b"/>
      <path id="Path_83" data-name="Path 83" d="M351.537,304.532a14.156,14.156,0,0,1-4.943-.895,2.048,2.048,0,0,1-.185-3.755c5.8-2.855,7.617-8.962,5.247-17.659a2.045,2.045,0,0,1,1.687-2.565c.226-.026,5.5-.712,10.885,4.336a2.043,2.043,0,0,1,.609,1.1,38.524,38.524,0,0,1,.622,4.684,13.834,13.834,0,0,1-12.392,14.669A13.585,13.585,0,0,1,351.537,304.532Zm4.725-20.555c1.572,8.289-.723,13.352-3.656,16.4l.006,0a9.75,9.75,0,0,0,8.761-10.316,34.184,34.184,0,0,0-.432-3.518A11.835,11.835,0,0,0,356.262,283.977Zm0,0" transform="translate(-241.73 -195.481)" fill="#101010"/>
      <path id="Path_84" data-name="Path 84" d="M147.928,45.191a2.04,2.04,0,0,1-1.447-.6l-6.036-6.034a2.047,2.047,0,0,1,2.9-2.894l6.035,6.034a2.047,2.047,0,0,1-1.448,3.494Zm0,0" transform="translate(-97.91 -24.265)" fill="#f4f4f4"/>
      <path id="Path_85" data-name="Path 85" d="M90.4,205.617a37.545,37.545,0,1,1,12.139,9.253" transform="translate(-56.869 -100.196)" fill="#a288e3"/>
      <path id="Path_86" data-name="Path 86" d="M113.939,215.8a39.483,39.483,0,0,1-17.09-3.888,2.047,2.047,0,0,1,1.771-3.691,35.361,35.361,0,1,0-11.479-8.748,2.046,2.046,0,1,1-3.09,2.682A39.562,39.562,0,1,1,113.939,215.8Zm0,0" transform="translate(-52.068 -95.398)" fill="#101010"/>
      <path id="Path_87" data-name="Path 87" d="M123.124,363.2a2.026,2.026,0,0,1-1.168-.368,39.784,39.784,0,0,1-3.188-2.463,2.047,2.047,0,1,1,2.667-3.106,35.829,35.829,0,0,0,2.86,2.21,2.048,2.048,0,0,1-1.171,3.727Zm0,0" transform="translate(-82.653 -249.497)" fill="#101010"/>
      <path id="Path_88" data-name="Path 88" d="M254.947,259.84a2.047,2.047,0,0,1-2.048-2.048,2.6,2.6,0,1,0-5.2,0,2.047,2.047,0,1,1-4.094,0,6.694,6.694,0,1,1,13.388,0A2.046,2.046,0,0,1,254.947,259.84Zm0,0" transform="translate(-170.555 -175.517)" fill="#101010"/>
      <path id="Path_89" data-name="Path 89" d="M129.849,259.84a2.047,2.047,0,0,1-2.048-2.048,2.6,2.6,0,1,0-5.2,0,2.047,2.047,0,1,1-4.094,0,6.694,6.694,0,1,1,13.387,0A2.046,2.046,0,0,1,129.849,259.84Zm0,0" transform="translate(-82.97 -175.517)" fill="#101010"/>
      <path id="Path_90" data-name="Path 90" d="M25.334,3.054A2.647,2.647,0,1,1,22.686.406,2.647,2.647,0,0,1,25.334,3.054Zm0,0" transform="translate(-14.029)" fill="#f4f4f4"/>
      <path id="Path_91" data-name="Path 91" d="M414.451,166.893a2.647,2.647,0,1,1-2.647-2.647A2.647,2.647,0,0,1,414.451,166.893Zm0,0" transform="translate(-286.462 -114.71)" fill="#f4f4f4"/>
      <path id="Path_92" data-name="Path 92" d="M189.669,300.868c-8.741-3.87-17.121-4.29-25.009,0V290.15c8.209-4.8,16.549-4.661,25.009,0Zm0,0" transform="translate(-115.283 -200.374)" fill="#de3c4b"/>
      <path id="Path_93" data-name="Path 93" d="M184.888,298.134a2.038,2.038,0,0,1-.827-.176c-8.659-3.83-16.247-3.855-23.2-.073a2.048,2.048,0,0,1-3.024-1.8V285.37a2.049,2.049,0,0,1,1.013-1.768c8.711-5.1,17.8-5.107,27.03-.026a2.047,2.047,0,0,1,1.06,1.793v10.717a2.049,2.049,0,0,1-2.048,2.047Zm-13.15-7.141a32.748,32.748,0,0,1,11.1,2.066V286.6c-7.231-3.649-14.095-3.66-20.915-.032v6.3A26.306,26.306,0,0,1,171.738,290.994Zm0,0" transform="translate(-110.502 -195.594)" fill="#101010"/>
      <path id="Path_94" data-name="Path 94" d="M159.88,346.671a2.047,2.047,0,0,1-2.048-2.048V329.4a2.047,2.047,0,0,1,4.094,0v15.225A2.046,2.046,0,0,1,159.88,346.671Zm0,0" transform="translate(-110.502 -228.905)" fill="#101010"/>
      <path id="Path_95" data-name="Path 95" d="M243.281,346.759a2.046,2.046,0,0,1-2.046-2.048V329.4a2.047,2.047,0,1,1,4.094,0v15.317A2.047,2.047,0,0,1,243.281,346.759Zm0,0" transform="translate(-168.895 -228.902)" fill="#101010"/>
    </g>
  </svg>
);

export const Staryu = () => isMobile ? (
  <svg width="77.511" height="72" viewBox="0 0 77.511 72">
    <g id="StaryuMobile" transform="translate(0 -0.137)">
      <g id="Group_504" data-name="Group 504" transform="translate(0 4.528)">
        <path id="Path_113" data-name="Path 113" d="M378.452,78.653a1.244,1.244,0,0,1-1.244-1.244V73.647a1.244,1.244,0,1,1,2.488,0v3.762A1.243,1.243,0,0,1,378.452,78.653Zm0,0" transform="translate(-308.456 -63.622)" fill="#f4f4f4"/>
        <path id="Path_114" data-name="Path 114" d="M378.452,30.481a1.244,1.244,0,0,1-1.244-1.245V25.475a1.244,1.244,0,1,1,2.488,0v3.761A1.244,1.244,0,0,1,378.452,30.481Zm0,0" transform="translate(-308.456 -24.23)" fill="#f4f4f4"/>
        <path id="Path_115" data-name="Path 115" d="M395.977,61.125h-3.763a1.244,1.244,0,1,1,0-2.488h3.763a1.244,1.244,0,0,1,0,2.488Zm0,0" transform="translate(-319.709 -52.366)" fill="#f4f4f4"/>
        <path id="Path_116" data-name="Path 116" d="M347.8,61.125h-3.764a1.244,1.244,0,1,1,0-2.488H347.8a1.244,1.244,0,0,1,0,2.488Zm0,0" transform="translate(-280.317 -52.366)" fill="#f4f4f4"/>
        <path id="Path_117" data-name="Path 117" d="M3.834,329.148a3.848,3.848,0,1,1,1.119-.167A3.821,3.821,0,0,1,3.834,329.148Zm0-5.189a1.349,1.349,0,1,0,1.292.958A1.35,1.35,0,0,0,3.838,323.96Zm0,0" transform="translate(0 -267.295)" fill="#f4f4f4"/>
        <path id="Path_118" data-name="Path 118" d="M358.822,226.745a3.853,3.853,0,1,1,1.119-.167A3.829,3.829,0,0,1,358.822,226.745Zm0-5.19a1.35,1.35,0,1,0,.392,2.641,1.349,1.349,0,0,0,.9-1.682A1.352,1.352,0,0,0,358.826,221.555Zm0,0" transform="translate(-290.286 -183.554)" fill="#f4f4f4"/>
        <path id="Path_119" data-name="Path 119" d="M359.727,350.033a1.244,1.244,0,0,1-.88-2.124l3.669-3.667A1.244,1.244,0,1,1,364.275,346l-3.668,3.667A1.24,1.24,0,0,1,359.727,350.033Zm0,0" transform="translate(-293.144 -285.618)" fill="#f4f4f4"/>
        <path id="Path_120" data-name="Path 120" d="M363.395,350.033a1.24,1.24,0,0,1-.88-.365L358.847,346a1.244,1.244,0,1,1,1.759-1.759l3.669,3.667a1.244,1.244,0,0,1-.88,2.124Zm0,0" transform="translate(-293.144 -285.618)" fill="#f4f4f4"/>
        <path id="Path_121" data-name="Path 121" d="M21.914,47.558a1.244,1.244,0,0,1-.88-2.124l4.873-4.873a1.244,1.244,0,0,1,1.76,1.759l-4.874,4.873A1.237,1.237,0,0,1,21.914,47.558Zm0,0" transform="translate(-16.902 -37.287)" fill="#f4f4f4"/>
        <path id="Path_122" data-name="Path 122" d="M26.788,47.558a1.243,1.243,0,0,1-.88-.365l-4.873-4.873a1.244,1.244,0,0,1,1.759-1.759l4.874,4.873a1.244,1.244,0,0,1-.88,2.124Zm0,0" transform="translate(-16.902 -37.287)" fill="#f4f4f4"/>
      </g>
      <path id="Path_123" data-name="Path 123" d="M54.943,29.9l25.1,3.978-18.425,17.5,3.972,25.1L43.249,64.362,20.605,75.9l4.619-24.989L7.258,32.935l25.193-3.329L43.991,6.965Zm0,0" transform="translate(-5.935 -5.584)" fill="#bf7e68"/>
      <path id="Path_124" data-name="Path 124" d="M60,72.137a1.244,1.244,0,0,1-.593-.15l-21.763-11.8L15.586,71.42A1.243,1.243,0,0,1,13.8,70.086L18.3,45.74.794,28.231a1.244,1.244,0,0,1,.717-2.113l24.544-3.244L37.3.816A1.245,1.245,0,0,1,38.408.137h.016A1.244,1.244,0,0,1,39.53.844L50.2,23.187l24.453,3.876a1.244,1.244,0,0,1,.662,2.13L57.364,46.245,61.233,70.7A1.245,1.245,0,0,1,60,72.137Zm-22.339-14.6a1.24,1.24,0,0,1,.593.15L58.381,68.6,54.8,45.988a1.243,1.243,0,0,1,.372-1.1l16.6-15.766L49.163,25.542a1.241,1.241,0,0,1-.928-.693L38.371,4.193l-10.4,20.394a1.244,1.244,0,0,1-.946.669l-22.693,3L20.52,44.444a1.244,1.244,0,0,1,.344,1.106L16.7,68.059,37.1,57.67A1.241,1.241,0,0,1,37.666,57.534Zm0,0" transform="translate(-0.352)" fill="#101010"/>
      <path id="Path_125" data-name="Path 125" d="M132.5,127.033H128.54a14.885,14.885,0,0,0-4.259-8.158l3.907-3.907a2.488,2.488,0,1,0-3.52-3.519L120.116,116a14.8,14.8,0,0,0-12.919.172l-3.5-4.809a2.488,2.488,0,1,0-4.025,2.928l3.5,4.8a14.882,14.882,0,0,0-4.038,7.936H95.66a2.489,2.489,0,0,0-2.488,2.489v.026a2.489,2.489,0,0,0,2.488,2.488h3.47a14.872,14.872,0,0,0,4.038,7.936l-3.5,4.8A2.488,2.488,0,1,0,103.7,147.7l3.5-4.809a14.827,14.827,0,0,0,4.15,1.348v5.2a2.489,2.489,0,0,0,4.977,0v-5.2a14.833,14.833,0,0,0,3.791-1.175l4.554,4.553a2.488,2.488,0,1,0,3.519-3.519l-3.907-3.907a14.871,14.871,0,0,0,4.259-8.158H132.5a2.489,2.489,0,0,0,2.489-2.488v-.026A2.489,2.489,0,0,0,132.5,127.033Zm0,0" transform="translate(-76.19 -90.119)" fill="#ffce56"/>
      <path id="Path_126" data-name="Path 126" d="M108.251,147.588a3.736,3.736,0,0,1-3.732-3.733v-4.184a16.05,16.05,0,0,1-2.5-.81l-2.9,3.987a3.733,3.733,0,0,1-6.037-4.391l2.9-3.982a16.025,16.025,0,0,1-3.451-6.782H90.076a3.746,3.746,0,0,1,0-7.492h2.453a16.021,16.021,0,0,1,3.451-6.782l-2.9-3.981a3.733,3.733,0,1,1,6.037-4.393l2.9,3.986a16.075,16.075,0,0,1,12.229-.1l3.956-3.955a3.733,3.733,0,0,1,5.28,5.279l-3.053,3.052a16,16,0,0,1,3.541,6.888h2.942a3.746,3.746,0,0,1,0,7.492h-2.942a15.981,15.981,0,0,1-3.541,6.888l3.052,3.053a3.733,3.733,0,1,1-5.279,5.279l-3.956-3.956a15.991,15.991,0,0,1-2.265.716v4.184A3.737,3.737,0,0,1,108.251,147.588Zm-6.638-11.525a1.244,1.244,0,0,1,.553.13,13.592,13.592,0,0,0,3.8,1.235,1.244,1.244,0,0,1,1.039,1.227v5.2a1.244,1.244,0,1,0,2.488,0v-5.2a1.244,1.244,0,0,1,1.037-1.227,13.616,13.616,0,0,0,3.475-1.076,1.247,1.247,0,0,1,1.4.249l4.554,4.553a1.244,1.244,0,1,0,1.759-1.759l-3.907-3.908a1.244,1.244,0,0,1,.009-1.768,13.577,13.577,0,0,0,3.9-7.476,1.244,1.244,0,0,1,1.226-1.037h3.959a1.258,1.258,0,0,0,0-2.515h-3.959a1.244,1.244,0,0,1-1.226-1.036,13.583,13.583,0,0,0-3.9-7.478,1.245,1.245,0,0,1-.009-1.769l3.907-3.907a1.244,1.244,0,1,0-1.76-1.759l-4.554,4.553a1.246,1.246,0,0,1-1.4.249,13.611,13.611,0,0,0-11.84.158,1.247,1.247,0,0,1-1.561-.382l-3.5-4.809a1.245,1.245,0,1,0-2.012,1.465l3.5,4.8a1.246,1.246,0,0,1-.117,1.6,13.59,13.59,0,0,0-3.7,7.274,1.244,1.244,0,0,1-1.226,1.036h-3.47a1.258,1.258,0,0,0,0,2.515h3.469a1.244,1.244,0,0,1,1.227,1.037,13.585,13.585,0,0,0,3.7,7.273,1.244,1.244,0,0,1,.117,1.6l-3.5,4.8a1.244,1.244,0,0,0,.274,1.738,1.255,1.255,0,0,0,.926.224,1.236,1.236,0,0,0,.812-.5l3.5-4.809A1.242,1.242,0,0,1,101.613,136.063Zm0,0" transform="translate(-70.607 -84.531)" fill="#101010"/>
      <path id="Path_127" data-name="Path 127" d="M181.573,183.517a7.161,7.161,0,1,1-7.162-7.162A7.161,7.161,0,0,1,181.573,183.517Zm0,0" transform="translate(-136.767 -144.1)" fill="#bd53b5"/>
      <path id="Path_128" data-name="Path 128" d="M168.827,186.343a8.406,8.406,0,1,1,8.406-8.406A8.415,8.415,0,0,1,168.827,186.343Zm0-14.323a5.917,5.917,0,1,0,5.918,5.917A5.924,5.924,0,0,0,168.827,172.02Zm0,0" transform="translate(-131.183 -138.52)" fill="#4c241d"/>
      <path id="Path_129" data-name="Path 129" d="M218.345,177.18a2.489,2.489,0,1,1-2.489-2.488A2.489,2.489,0,0,1,218.345,177.18Zm0,0" transform="translate(-174.478 -142.74)" fill="#fff"/>
    </g>
  </svg>
) : (
  <svg width="129.79" height="120" viewBox="0 0 129.79 120">
    <g id="StaryuDesktop" transform="translate(0 -0.137)">
      <g id="Group_504" data-name="Group 504" transform="translate(0 7.456)">
        <path id="Path_113" data-name="Path 113" d="M379.281,82.82a2.073,2.073,0,0,1-2.074-2.073v-6.27a2.074,2.074,0,1,1,4.147,0v6.27A2.072,2.072,0,0,1,379.281,82.82Zm0,0" transform="translate(-262.017 -57.769)" fill="#f4f4f4"/>
        <path id="Path_114" data-name="Path 114" d="M379.281,34.648a2.074,2.074,0,0,1-2.074-2.074V26.3a2.074,2.074,0,1,1,4.147,0v6.269A2.073,2.073,0,0,1,379.281,34.648Zm0,0" transform="translate(-262.017 -24.23)" fill="#f4f4f4"/>
        <path id="Path_115" data-name="Path 115" d="M399.315,62.784h-6.272a2.074,2.074,0,1,1,0-4.147h6.272a2.074,2.074,0,0,1,0,4.147Zm0,0" transform="translate(-271.598 -48.185)" fill="#f4f4f4"/>
        <path id="Path_116" data-name="Path 116" d="M351.144,62.784h-6.272a2.074,2.074,0,1,1,0-4.147h6.272a2.074,2.074,0,0,1,0,4.147Zm0,0" transform="translate(-238.06 -48.185)" fill="#f4f4f4"/>
        <path id="Path_117" data-name="Path 117" d="M6.391,334.267a6.414,6.414,0,1,1,1.864-.278A6.368,6.368,0,0,1,6.391,334.267Zm.006-8.648a2.249,2.249,0,1,0,2.154,1.6A2.25,2.25,0,0,0,6.4,325.619Zm0,0" transform="translate(0 -231.177)" fill="#f4f4f4"/>
        <path id="Path_118" data-name="Path 118" d="M361.378,231.864a6.421,6.421,0,1,1,1.864-.279A6.382,6.382,0,0,1,361.378,231.864Zm.007-8.649a2.25,2.25,0,1,0,.654,4.4,2.248,2.248,0,0,0,1.5-2.8A2.254,2.254,0,0,0,361.385,223.214Zm0,0" transform="translate(-246.547 -159.88)" fill="#f4f4f4"/>
        <path id="Path_119" data-name="Path 119" d="M360.556,354.137a2.074,2.074,0,0,1-1.467-3.54l6.115-6.111a2.073,2.073,0,1,1,2.932,2.932l-6.113,6.111A2.066,2.066,0,0,1,360.556,354.137Zm0,0" transform="translate(-248.98 -246.778)" fill="#f4f4f4"/>
        <path id="Path_120" data-name="Path 120" d="M366.669,354.137a2.066,2.066,0,0,1-1.467-.608l-6.113-6.111a2.073,2.073,0,1,1,2.932-2.932l6.115,6.111a2.074,2.074,0,0,1-1.467,3.54Zm0,0" transform="translate(-248.98 -246.778)" fill="#f4f4f4"/>
        <path id="Path_121" data-name="Path 121" d="M22.744,52.465a2.074,2.074,0,0,1-1.467-3.54L29.4,40.8a2.074,2.074,0,1,1,2.933,2.932l-8.124,8.121A2.062,2.062,0,0,1,22.744,52.465Zm0,0" transform="translate(-14.391 -35.347)" fill="#f4f4f4"/>
        <path id="Path_122" data-name="Path 122" d="M30.866,52.465a2.071,2.071,0,0,1-1.467-.608l-8.122-8.121A2.073,2.073,0,0,1,24.209,40.8l8.124,8.121a2.074,2.074,0,0,1-1.467,3.54Zm0,0" transform="translate(-14.391 -35.347)" fill="#f4f4f4"/>
      </g>
      <path id="Path_123" data-name="Path 123" d="M87.128,45.185l42.039,6.631L98.306,80.986l6.653,41.833L67.542,102.626,29.614,121.849,37.351,80.2,7.258,50.248l42.2-5.549L68.785,6.965Zm0,0" transform="translate(-5.053 -4.754)" fill="#bf7e68"/>
      <path id="Path_124" data-name="Path 124" d="M100.2,120.137a2.091,2.091,0,0,1-.993-.25L62.759,100.216,25.812,118.943a2.1,2.1,0,0,1-2.193-.184,2.073,2.073,0,0,1-.8-2.04l7.537-40.576L1.04,46.961a2.071,2.071,0,0,1,1.2-3.522l41.1-5.407L62.175,1.269A2.086,2.086,0,0,1,64.031.137h.026a2.084,2.084,0,0,1,1.854,1.179L83.78,38.553l40.951,6.46a2.07,2.07,0,0,1,1.109,3.55L95.777,76.984l6.48,40.754a2.08,2.08,0,0,1-2.058,2.4ZM62.789,95.8a2.084,2.084,0,0,1,.993.25l33.7,18.188L91.489,76.556a2.065,2.065,0,0,1,.624-1.826l27.794-26.276L82.044,42.479a2.078,2.078,0,0,1-1.554-1.155L63.97,6.9,46.561,40.886A2.084,2.084,0,0,1,44.976,42l-38,5,27.1,26.984a2.068,2.068,0,0,1,.576,1.843l-6.969,37.516,34.16-17.315A2.086,2.086,0,0,1,62.789,95.8Zm0,0" transform="translate(-0.3)" fill="#101010"/>
      <path id="Path_125" data-name="Path 125" d="M158.717,138.161h-6.6a24.809,24.809,0,0,0-7.1-13.6l6.512-6.512a4.147,4.147,0,0,0-5.867-5.864l-7.588,7.59a24.671,24.671,0,0,0-21.531.287l-5.832-8.016a4.147,4.147,0,1,0-6.708,4.879l5.825,8.006a24.8,24.8,0,0,0-6.729,13.226H97.319a4.148,4.148,0,0,0-4.147,4.148v.044a4.148,4.148,0,0,0,4.147,4.147H103.1a24.786,24.786,0,0,0,6.729,13.226l-5.825,8.006a4.147,4.147,0,1,0,6.707,4.881l5.832-8.016a24.711,24.711,0,0,0,6.917,2.246v8.668a4.148,4.148,0,0,0,8.3,0v-8.668a24.722,24.722,0,0,0,6.319-1.958l7.59,7.588a4.147,4.147,0,0,0,5.865-5.865L145.02,160.1a24.785,24.785,0,0,0,7.1-13.6h6.6a4.148,4.148,0,0,0,4.148-4.147v-.044A4.148,4.148,0,0,0,158.717,138.161Zm0,0" transform="translate(-64.581 -76.728)" fill="#ffce56"/>
      <path id="Path_126" data-name="Path 126" d="M122.856,176.975a6.227,6.227,0,0,1-6.22-6.221v-6.973a26.755,26.755,0,0,1-4.163-1.35l-4.837,6.645a6.221,6.221,0,0,1-10.061-7.319l4.828-6.637a26.709,26.709,0,0,1-5.752-11.3H92.564a6.243,6.243,0,0,1,0-12.487h4.088a26.7,26.7,0,0,1,5.752-11.3l-4.828-6.634a6.222,6.222,0,1,1,10.061-7.321l4.834,6.643a26.792,26.792,0,0,1,20.383-.159l6.593-6.592a6.222,6.222,0,0,1,8.8,8.8l-5.088,5.087a26.666,26.666,0,0,1,5.9,11.481h4.9a6.243,6.243,0,0,1,0,12.487h-4.9a26.636,26.636,0,0,1-5.9,11.479l5.087,5.088a6.221,6.221,0,1,1-8.8,8.8l-6.594-6.594a26.657,26.657,0,0,1-3.775,1.194v6.973A6.228,6.228,0,0,1,122.856,176.975Zm-11.063-19.209a2.074,2.074,0,0,1,.922.217,22.654,22.654,0,0,0,6.337,2.058,2.074,2.074,0,0,1,1.731,2.046v8.667a2.074,2.074,0,1,0,4.147,0v-8.667a2.074,2.074,0,0,1,1.729-2.046,22.693,22.693,0,0,0,5.792-1.794,2.079,2.079,0,0,1,2.339.415l7.59,7.588a2.073,2.073,0,1,0,2.932-2.932l-6.511-6.513a2.073,2.073,0,0,1,.014-2.946,22.628,22.628,0,0,0,6.506-12.461,2.073,2.073,0,0,1,2.043-1.728h6.6a2.1,2.1,0,0,0,0-4.192h-6.6a2.073,2.073,0,0,1-2.043-1.727,22.639,22.639,0,0,0-6.506-12.463,2.074,2.074,0,0,1-.014-2.948l6.512-6.511a2.074,2.074,0,1,0-2.933-2.932l-7.59,7.588a2.077,2.077,0,0,1-2.34.415,22.685,22.685,0,0,0-19.733.263,2.079,2.079,0,0,1-2.6-.637l-5.832-8.016a2.074,2.074,0,1,0-3.353,2.442l5.825,8a2.076,2.076,0,0,1-.195,2.671,22.651,22.651,0,0,0-6.168,12.124,2.073,2.073,0,0,1-2.043,1.727H92.565a2.1,2.1,0,0,0,0,4.192h5.782a2.074,2.074,0,0,1,2.045,1.728,22.641,22.641,0,0,0,6.167,12.122,2.074,2.074,0,0,1,.195,2.671l-5.825,8a2.073,2.073,0,0,0,.457,2.9,2.091,2.091,0,0,0,1.544.373,2.06,2.06,0,0,0,1.353-.828l5.833-8.016A2.07,2.07,0,0,1,111.793,157.766Zm0,0" transform="translate(-59.829 -71.97)" fill="#101010"/>
      <path id="Path_127" data-name="Path 127" d="M191.121,188.292a11.936,11.936,0,1,1-11.936-11.936A11.935,11.935,0,0,1,191.121,188.292Zm0,0" transform="translate(-116.153 -122.688)" fill="#bd53b5"/>
      <path id="Path_128" data-name="Path 128" d="M174.431,197.551a14.01,14.01,0,1,1,14.01-14.01A14.026,14.026,0,0,1,174.431,197.551Zm0-23.872a9.862,9.862,0,1,0,9.863,9.862A9.873,9.873,0,0,0,174.431,173.678Zm0,0" transform="translate(-111.399 -117.937)" fill="#4c241d"/>
      <path id="Path_129" data-name="Path 129" d="M221.663,178.839a4.148,4.148,0,1,1-4.148-4.147A4.148,4.148,0,0,1,221.663,178.839Zm0,0" transform="translate(-148.228 -121.53)" fill="#fff"/>
    </g>
  </svg>
);

export const MasterBall = () => isMobile ? (
  <svg width="34.624" height="40" viewBox="0 0 34.624 40">
    <g id="MasterBallMobile" transform="translate(-17.093 -4.775)">
      <path id="Path_159" data-name="Path 159" d="M96.427,111.953A14.942,14.942,0,0,1,111.3,126.959H81.551A14.942,14.942,0,0,1,96.427,111.953Zm0,0" transform="translate(-63.764 -97.885)" fill="#a288e3"/>
      <path id="Path_160" data-name="Path 160" d="M105.172,121.523H75.421a.694.694,0,0,1-.694-.694,15.57,15.57,0,1,1,31.14,0A.694.694,0,0,1,105.172,121.523Zm-29.04-1.388h28.329a14.176,14.176,0,0,0-28.329,0Zm0,0" transform="translate(-57.633 -91.755)" fill="#101010"/>
      <path id="Path_161" data-name="Path 161" d="M84.205,268.062a15.029,15.029,0,0,1-2.655-8.558H111.3A14.941,14.941,0,0,1,96.427,274.51a14.709,14.709,0,0,1-7.127-1.831" transform="translate(-63.764 -230.429)" fill="#f4f4f4"/>
      <path id="Path_162" data-name="Path 162" d="M90.3,269.07a15.484,15.484,0,0,1-7.462-1.917.694.694,0,0,1,.671-1.216,14.1,14.1,0,0,0,6.792,1.744,14.256,14.256,0,0,0,14.165-13.617H76.131a14.3,14.3,0,0,0,2.516,7.47.694.694,0,0,1-1.143.788,15.7,15.7,0,0,1-2.777-8.952.694.694,0,0,1,.694-.694h29.751a.7.7,0,0,1,.694.694A15.654,15.654,0,0,1,90.3,269.07Zm0,0" transform="translate(-57.633 -224.296)" fill="#101010"/>
      <path id="Path_163" data-name="Path 163" d="M121.114,361.129a.689.689,0,0,1-.438-.156c-.357-.29-.705-.6-1.036-.923a.694.694,0,1,1,.968-1c.3.294.62.577.945.842a.694.694,0,0,1-.439,1.232Zm0,0" transform="translate(-97.79 -319.679)" fill="#101010"/>
      <path id="Path_164" data-name="Path 164" d="M190.6,218.81a4.752,4.752,0,1,1-4.752-4.752A4.752,4.752,0,0,1,190.6,218.81Zm0,0" transform="translate(-153.186 -189.606)" fill="#edebdc"/>
      <path id="Path_165" data-name="Path 165" d="M179.715,218.123a5.446,5.446,0,1,1,5.447-5.446A5.452,5.452,0,0,1,179.715,218.123Zm0-9.5a4.058,4.058,0,1,0,4.058,4.058A4.062,4.062,0,0,0,179.715,208.619Zm0,0" transform="translate(-147.053 -183.472)" fill="#101010"/>
      <path id="Path_166" data-name="Path 166" d="M211.618,242.51a2.069,2.069,0,1,1-2.069-2.069A2.068,2.068,0,0,1,211.618,242.51Zm0,0" transform="translate(-176.886 -213.305)" fill="#edebdc"/>
      <g id="Group_511" data-name="Group 511" transform="translate(18.18 4.775)">
        <path id="Path_167" data-name="Path 167" d="M203.415,239.143a2.763,2.763,0,1,1,2.763-2.763A2.766,2.766,0,0,1,203.415,239.143Zm0-4.137a1.374,1.374,0,1,0,1.375,1.374A1.376,1.376,0,0,0,203.415,235.006Zm0,0" transform="translate(-188.933 -211.95)" fill="#f4f4f4"/>
        <path id="Path_168" data-name="Path 168" d="M357.425,24.042a.694.694,0,0,1-.694-.694V21.264a.694.694,0,0,1,1.388,0v2.083A.7.7,0,0,1,357.425,24.042Zm0,0" transform="translate(-329.137 -20.57)" fill="#f4f4f4"/>
        <path id="Path_169" data-name="Path 169" d="M357.425,71.827a.694.694,0,0,1-.694-.694V69.05a.694.694,0,1,1,1.388,0v2.083A.694.694,0,0,1,357.425,71.827Zm0,0" transform="translate(-329.137 -63.496)" fill="#f4f4f4"/>
        <path id="Path_170" data-name="Path 170" d="M325.375,56.092h-2.083a.694.694,0,0,1,0-1.388h2.083a.694.694,0,1,1,0,1.388Zm0,0" transform="translate(-298.476 -51.232)" fill="#f4f4f4"/>
        <path id="Path_171" data-name="Path 171" d="M373.16,56.092h-2.083a.694.694,0,1,1,0-1.388h2.083a.694.694,0,1,1,0,1.388Zm0,0" transform="translate(-341.401 -51.232)" fill="#f4f4f4"/>
        <path id="Path_174" data-name="Path 174" d="M86.11,63.481a.694.694,0,0,1-.491-1.185l2.047-2.047a.694.694,0,0,1,.982.982L86.6,63.278A.692.692,0,0,1,86.11,63.481Zm0,0" transform="translate(-85.416 -56.031)" fill="#f4f4f4"/>
        <path id="Path_175" data-name="Path 175" d="M88.157,63.481a.69.69,0,0,1-.491-.2l-2.047-2.047a.694.694,0,1,1,.982-.982L88.648,62.3a.694.694,0,0,1-.491,1.185Zm0,0" transform="translate(-85.416 -56.031)" fill="#f4f4f4"/>
        <path id="Path_176" data-name="Path 176" d="M203.577,22.943a1.158,1.158,0,1,1-1.158-1.158A1.158,1.158,0,0,1,203.577,22.943Zm0,0" transform="translate(-189.48 -21.662)" fill="#f4f4f4"/>
        <path id="Path_177" data-name="Path 177" d="M394.722,166.3a1.158,1.158,0,1,1-1.158-1.158A1.157,1.157,0,0,1,394.722,166.3Zm0,0" transform="translate(-361.185 -150.441)" fill="#f4f4f4"/>
      </g>
      <path id="Path_178" data-name="Path 178" d="M272.807,119.262c-2.506-1.769-5.613-1.679-6.94.2s-.37,4.837,2.136,6.606,5.613,1.679,6.94-.2S275.313,121.031,272.807,119.262Zm0,0" transform="translate(-228.798 -103.31)" fill="#de3c4b"/>
      <path id="Path_179" data-name="Path 179" d="M265.691,121.95a7.3,7.3,0,0,1-4.154-1.388c-2.815-1.987-3.848-5.384-2.3-7.574s5.091-2.355,7.907-.367,3.848,5.384,2.3,7.574A4.46,4.46,0,0,1,265.691,121.95Zm-2.718-9.345a3.076,3.076,0,0,0-2.6,1.183c-1.1,1.564-.22,4.093,1.97,5.638s4.869,1.529,5.972-.034.22-4.093-1.97-5.638A5.967,5.967,0,0,0,262.973,112.6Zm0,0" transform="translate(-222.731 -97.236)" fill="#101010"/>
      <path id="Path_180" data-name="Path 180" d="M97.451,119.438c-1.326-1.88-4.434-1.97-6.94-.2s-3.462,4.726-2.136,6.606,4.434,1.969,6.94.2S98.777,121.317,97.451,119.438Zm0,0" transform="translate(-69.357 -103.287)" fill="#de3c4b"/>
      <path id="Path_181" data-name="Path 181" d="M85.494,121.952a4.458,4.458,0,0,1-3.753-1.756c-1.545-2.19-.512-5.587,2.3-7.574s6.362-1.821,7.907.367.512,5.587-2.3,7.574A7.3,7.3,0,0,1,85.494,121.952Zm2.718-9.345a5.966,5.966,0,0,0-3.367,1.149c-2.189,1.545-3.073,4.075-1.969,5.638s3.782,1.58,5.972.034,3.073-4.075,1.969-5.638A3.077,3.077,0,0,0,88.212,112.607Zm0,0" transform="translate(-63.29 -97.238)" fill="#101010"/>
      <path id="Path_182" data-name="Path 182" d="M201.854,160.921a.693.693,0,0,1-.622-.384l-.68-1.36L200,160a.72.72,0,0,1-1.155,0l-.551-.828-.68,1.36a.694.694,0,0,1-1.242-.621l1.215-2.43a.695.695,0,0,1,1.2-.075l.637.956.638-.956a.695.695,0,0,1,1.2.075l1.215,2.43a.694.694,0,0,1-.62,1Zm0,0" transform="translate(-166.841 -138.442)" fill="#101010"/>
    </g>
  </svg>
) : (
  <svg width="55.399" height="64" viewBox="0 0 55.399 64">
    <g id="MasterBallDesktop" transform="translate(-17.093 -4.775)">
      <path id="Path_159" data-name="Path 159" d="M105.326,111.953A23.975,23.975,0,0,1,129.1,136.122H81.551A23.975,23.975,0,0,1,105.326,111.953Zm0,0" transform="translate(-63.34 -92.368)" fill="#a288e3"/>
      <path id="Path_160" data-name="Path 160" d="M123.4,131.534H75.836a1.114,1.114,0,0,1-1.11-1.118,24.9,24.9,0,1,1,49.787,0A1.114,1.114,0,0,1,123.4,131.534ZM76.973,129.3h45.294a22.659,22.659,0,0,0-45.294,0Zm0,0" transform="translate(-57.633 -86.658)" fill="#101010"/>
      <path id="Path_161" data-name="Path 161" d="M85.794,273.288A24.337,24.337,0,0,1,81.551,259.5H129.1a23.975,23.975,0,0,1-23.776,24.169,23.368,23.368,0,0,1-11.392-2.949" transform="translate(-63.34 -216.016)" fill="#f4f4f4"/>
      <path id="Path_162" data-name="Path 162" d="M99.619,279.081a24.613,24.613,0,0,1-11.931-3.088,1.121,1.121,0,0,1-.436-1.519,1.1,1.1,0,0,1,1.508-.439,22.413,22.413,0,0,0,10.859,2.81,22.869,22.869,0,0,0,22.647-21.932H76.973a23.143,23.143,0,0,0,4.022,12.031,1.123,1.123,0,0,1-.284,1.555,1.106,1.106,0,0,1-1.544-.286,25.417,25.417,0,0,1-4.44-14.418,1.114,1.114,0,0,1,1.11-1.118H123.4a1.115,1.115,0,0,1,1.11,1.118A25.122,25.122,0,0,1,99.619,279.081Zm0,0" transform="translate(-57.633 -210.306)" fill="#101010"/>
      <path id="Path_163" data-name="Path 163" d="M122.143,362.515a1.109,1.109,0,0,1-.705-.251c-.575-.467-1.136-.967-1.669-1.486a1.118,1.118,0,1,1,1.559-1.6c.486.473,1,.93,1.522,1.356a1.118,1.118,0,0,1-.706,1.985Zm0,0" transform="translate(-95.014 -299.095)" fill="#101010"/>
      <path id="Path_164" data-name="Path 164" d="M196.4,221.712a7.653,7.653,0,1,1-7.653-7.653A7.653,7.653,0,0,1,196.4,221.712Zm0,0" transform="translate(-146.739 -177.864)" fill="#edebdc"/>
      <path id="Path_165" data-name="Path 165" d="M183.041,224.774A8.772,8.772,0,1,1,191.813,216,8.781,8.781,0,0,1,183.041,224.774Zm0-15.307A6.535,6.535,0,1,0,189.577,216,6.543,6.543,0,0,0,183.041,209.467Zm0,0" transform="translate(-141.028 -172.157)" fill="#101010"/>
      <path id="Path_166" data-name="Path 166" d="M214.144,243.773a3.332,3.332,0,1,1-3.332-3.332A3.332,3.332,0,0,1,214.144,243.773Zm0,0" transform="translate(-168.805 -199.916)" fill="#edebdc"/>
      <g id="Group_511" data-name="Group 511" transform="translate(18.844 4.775)">
        <path id="Path_167" data-name="Path 167" d="M205.1,242.517a4.45,4.45,0,1,1,4.45-4.45A4.455,4.455,0,0,1,205.1,242.517Zm0-6.663a2.214,2.214,0,1,0,2.214,2.213A2.216,2.216,0,0,0,205.1,235.853Zm0,0" transform="translate(-181.93 -198.719)" fill="#f4f4f4"/>
        <path id="Path_168" data-name="Path 168" d="M357.848,26.161a1.118,1.118,0,0,1-1.118-1.118V21.688a1.118,1.118,0,0,1,2.236,0v3.355A1.119,1.119,0,0,1,357.848,26.161Zm0,0" transform="translate(-312.656 -20.57)" fill="#f4f4f4"/>
        <path id="Path_169" data-name="Path 169" d="M357.848,73.947a1.118,1.118,0,0,1-1.118-1.118V69.474a1.118,1.118,0,1,1,2.236,0v3.354A1.118,1.118,0,0,1,357.848,73.947Zm0,0" transform="translate(-312.656 -60.528)" fill="#f4f4f4"/>
        <path id="Path_170" data-name="Path 170" d="M327.07,56.939h-3.355a1.118,1.118,0,0,1,0-2.236h3.355a1.118,1.118,0,1,1,0,2.236Zm0,0" transform="translate(-284.041 -49.112)" fill="#f4f4f4"/>
        <path id="Path_171" data-name="Path 171" d="M374.855,56.939H371.5a1.118,1.118,0,1,1,0-2.236h3.354a1.118,1.118,0,1,1,0,2.236Zm0,0" transform="translate(-324.072 -49.112)" fill="#f4f4f4"/>
        <path id="Path_174" data-name="Path 174" d="M86.534,65.579a1.118,1.118,0,0,1-.791-1.909l3.3-3.3a1.118,1.118,0,1,1,1.581,1.581l-3.3,3.3A1.114,1.114,0,0,1,86.534,65.579Zm0,0" transform="translate(-85.416 -53.58)" fill="#f4f4f4"/>
        <path id="Path_175" data-name="Path 175" d="M89.831,65.579a1.112,1.112,0,0,1-.79-.328l-3.3-3.3a1.118,1.118,0,1,1,1.582-1.581l3.3,3.3a1.118,1.118,0,0,1-.791,1.909Zm0,0" transform="translate(-85.416 -53.58)" fill="#f4f4f4"/>
        <path id="Path_176" data-name="Path 176" d="M204.991,23.65a1.865,1.865,0,1,1-1.865-1.865A1.865,1.865,0,0,1,204.991,23.65Zm0,0" transform="translate(-182.425 -21.586)" fill="#f4f4f4"/>
        <path id="Path_177" data-name="Path 177" d="M396.136,167.01a1.865,1.865,0,1,1-1.865-1.865A1.864,1.864,0,0,1,396.136,167.01Zm0,0" transform="translate(-342.488 -141.463)" fill="#f4f4f4"/>
      </g>
      <path id="Path_178" data-name="Path 178" d="M277.41,120.038c-4.037-2.849-9.041-2.7-11.178.323s-.6,7.791,3.44,10.64,9.041,2.7,11.177-.323S281.446,122.887,277.41,120.038Zm0,0" transform="translate(-217.333 -97.397)" fill="#de3c4b"/>
      <path id="Path_179" data-name="Path 179" d="M270.072,128.495a11.762,11.762,0,0,1-6.691-2.236c-4.533-3.2-6.2-8.672-3.709-12.2s8.2-3.792,12.736-.592,6.2,8.671,3.709,12.2A7.183,7.183,0,0,1,270.072,128.495Zm-4.378-15.051a4.955,4.955,0,0,0-4.2,1.905c-1.777,2.518-.354,6.593,3.172,9.081s7.841,2.463,9.619-.055.354-6.593-3.172-9.081A9.61,9.61,0,0,0,265.694,113.444Zm0,0" transform="translate(-211.685 -91.739)" fill="#101010"/>
      <path id="Path_180" data-name="Path 180" d="M103.357,120.336c-2.136-3.028-7.141-3.172-11.178-.324s-5.576,7.612-3.44,10.64,7.141,3.172,11.178.323S105.494,123.363,103.357,120.336Zm0,0" transform="translate(-68.547 -97.376)" fill="#de3c4b"/>
      <path id="Path_181" data-name="Path 181" d="M88.223,128.5a7.181,7.181,0,0,1-6.045-2.829c-2.488-3.527-.825-9,3.709-12.2s10.247-2.933,12.736.592.824,9-3.709,12.2A11.759,11.759,0,0,1,88.223,128.5ZM92.6,113.446a9.608,9.608,0,0,0-5.423,1.85c-3.526,2.488-4.949,6.563-3.172,9.081s6.092,2.544,9.619.055,4.949-6.563,3.172-9.081A4.956,4.956,0,0,0,92.6,113.446Zm0,0" transform="translate(-62.899 -91.742)" fill="#101010"/>
      <path id="Path_182" data-name="Path 182" d="M205.246,163.253a1.116,1.116,0,0,1-1-.618l-1.1-2.191-.888,1.333a1.16,1.16,0,0,1-1.861,0l-.887-1.333-1.1,2.191a1.118,1.118,0,0,1-2-1l1.957-3.914a1.119,1.119,0,0,1,1.93-.12l1.026,1.54,1.027-1.54a1.119,1.119,0,0,1,1.93.12l1.957,3.914a1.117,1.117,0,0,1-1,1.618Zm0,0" transform="translate(-159.452 -130.126)" fill="#101010"/>
    </g>
  </svg>
);

export const CopyIcon = ({ color }: Color) => isMobile ? (
  <svg width="18.008" height="20.676" viewBox="0 0 18.008 20.676">
    <g id="CopyIcon" transform="translate(-37.306 -741.662)">
      <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M14.8,1.5H4.685A1.7,1.7,0,0,0,3,3.2V15.082H4.685V3.2H14.8Zm2.528,3.4H8.055a1.7,1.7,0,0,0-1.685,1.7V18.478a1.7,1.7,0,0,0,1.685,1.7h9.268a1.7,1.7,0,0,0,1.685-1.7V6.593A1.7,1.7,0,0,0,17.323,4.9Zm0,13.582H8.055V6.593h9.268Z" transform="translate(34.306 740.162)" fill={color}/>
      <path id="Icon_material-content-copy-2" data-name="Icon material-content-copy" d="M14.8,1.5H4.685A1.7,1.7,0,0,0,3,3.2V15.082H4.685V3.2H14.8Zm2.528,3.4H8.055a1.7,1.7,0,0,0-1.685,1.7V18.478a1.7,1.7,0,0,0,1.685,1.7h9.268a1.7,1.7,0,0,0,1.685-1.7V6.593A1.7,1.7,0,0,0,17.323,4.9Zm0,13.582H8.055V6.593h9.268Z" transform="translate(36.306 742.162)" fill={color} opacity="0.2"/>
    </g>
  </svg>
) : (
  <svg width="21.15" height="24.675" viewBox="0 0 21.15 24.675">
    <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M18.585,1.5H5.226A2.241,2.241,0,0,0,3,3.743v15.7H5.226V3.743H18.585Zm3.34,4.486H9.679A2.241,2.241,0,0,0,7.453,8.23v15.7a2.241,2.241,0,0,0,2.226,2.243H21.924a2.241,2.241,0,0,0,2.226-2.243V8.23A2.241,2.241,0,0,0,21.924,5.986Zm0,17.946H9.679V8.23H21.924Z" transform="translate(-3 -1.5)" fill={color}/>
  </svg>
);

export const MailIconSmallDesktop = ({ color }: Color) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27.384" height="21.679" viewBox="0 0 27.384 21.679">
    <g id="Icon_feather-mail" data-name="Icon feather-mail" transform="translate(-1.607 -5)">
      <path id="Path_156" data-name="Path 156" d="M5.46,6H25.139A2.467,2.467,0,0,1,27.6,8.46V23.219a2.467,2.467,0,0,1-2.46,2.46H5.46A2.467,2.467,0,0,1,3,23.219V8.46A2.467,2.467,0,0,1,5.46,6Z" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_157" data-name="Path 157" d="M27.6,9,15.3,17.609,3,9" transform="translate(0 -0.54)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>
);

export const BackgroundDecoration = () => {
  const repeatCount = isMobile ? 7 : 3;

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    }}>
      {Array(repeatCount).fill(0).map(() => (
        <img 
          src={repeatable} 
          alt=""
          style={{ 
            opacity: isMobile ? 0.2 : 0.3,
            width: isMobile ? 180 : 'auto'
          }} 
        />
      ))}
    </div>
  );
};

export const GitHubIcon = ({ color }: Color) => isMobile ? (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <path id="Icon_zocial-github" data-name="Icon zocial-github" d="M0,15.788A15.377,15.377,0,0,1,1.283,9.58,15.471,15.471,0,0,1,9.78,1.1a15.755,15.755,0,0,1,12.441,0,15.606,15.606,0,0,1,8.5,8.48,15.666,15.666,0,0,1,0,12.416,15.72,15.72,0,0,1-8.5,8.544,15.755,15.755,0,0,1-12.441,0A15.584,15.584,0,0,1,1.283,22,15.377,15.377,0,0,1,0,15.788Zm2.5,0a13,13,0,0,0,2.693,8.064,14.414,14.414,0,0,0,6.958,4.96v-2.56a3.113,3.113,0,0,1,1.283-2.784,9.221,9.221,0,0,1-1.507-.224,7.585,7.585,0,0,1-2.79-1.024Q6.22,20.46,6.22,15.852a5.748,5.748,0,0,1,1.6-4.1,5.373,5.373,0,0,1,.16-4.1h.641a2.642,2.642,0,0,1,.8.16,11.034,11.034,0,0,1,2.79,1.408,15.153,15.153,0,0,1,3.848-.512,15.524,15.524,0,0,1,3.88.512,13.669,13.669,0,0,1,2.341-1.28,3.482,3.482,0,0,1,1.475-.32l.385.032a5.578,5.578,0,0,1,.16,4.1,5.748,5.748,0,0,1,1.6,4.1,7.492,7.492,0,0,1-1.764,5.408,6.449,6.449,0,0,1-2.533,1.6,11.046,11.046,0,0,1-2.918.608,3.183,3.183,0,0,1,1.315,2.784v2.56a14.52,14.52,0,0,0,6.83-5.024,13.023,13.023,0,0,0,2.629-8,13.463,13.463,0,0,0-1.058-5.248A13.217,13.217,0,0,0,21.226,3.4,13.27,13.27,0,0,0,16,2.316,13.66,13.66,0,0,0,10.741,3.4a14.1,14.1,0,0,0-4.3,2.848A14.842,14.842,0,0,0,3.559,10.54,13.463,13.463,0,0,0,2.5,15.788Z" transform="translate(0 0.18)" fill={color} />
  </svg>
) : (
  <svg width="40" height="40" viewBox="0 0 40 40">
    <path id="Icon_zocial-github-desktop" data-name="Icon zocial-github-desktop" d="M0,19.78a19.221,19.221,0,0,1,1.6-7.76A19.339,19.339,0,0,1,12.224,1.42a19.694,19.694,0,0,1,15.551,0A19.507,19.507,0,0,1,38.4,12.02a19.582,19.582,0,0,1,0,15.52A19.651,19.651,0,0,1,27.776,38.22a19.694,19.694,0,0,1-15.551,0A19.48,19.48,0,0,1,1.6,27.54,19.221,19.221,0,0,1,0,19.78Zm3.126,0A16.249,16.249,0,0,0,6.493,29.86a18.017,18.017,0,0,0,8.7,6.2v-3.2a3.891,3.891,0,0,1,1.6-3.48,11.526,11.526,0,0,1-1.884-.28,9.482,9.482,0,0,1-3.487-1.28q-3.647-2.2-3.647-7.96a7.185,7.185,0,0,1,2-5.12,6.717,6.717,0,0,1,.2-5.12h.8a3.3,3.3,0,0,1,1,.2,13.793,13.793,0,0,1,3.487,1.76,18.941,18.941,0,0,1,4.81-.64,19.405,19.405,0,0,1,4.85.64,17.087,17.087,0,0,1,2.926-1.6,4.352,4.352,0,0,1,1.844-.4l.481.04a6.973,6.973,0,0,1,.2,5.12,7.185,7.185,0,0,1,2,5.12q0,4.48-2.2,6.76a8.061,8.061,0,0,1-3.166,2,13.807,13.807,0,0,1-3.647.76,3.979,3.979,0,0,1,1.643,3.48v3.2a18.15,18.15,0,0,0,8.537-6.28,16.279,16.279,0,0,0,3.287-10,16.828,16.828,0,0,0-1.323-6.56A16.521,16.521,0,0,0,26.533,4.3,16.587,16.587,0,0,0,20,2.94,17.075,17.075,0,0,0,13.427,4.3,17.625,17.625,0,0,0,8.056,7.86a18.553,18.553,0,0,0-3.607,5.36,16.828,16.828,0,0,0-1.323,6.56Z" transform="translate(0 0.18)" fill={color} />
  </svg>
);

export const DiscordIcon = () => isMobile ? (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <g id="DiscordMobile" transform="translate(-32 -296)">
      <path id="Icon_awesome-discord" data-name="Icon awesome-discord" d="M15.591,13.3a1.467,1.467,0,0,1-1.37,1.554,1.476,1.476,0,0,1-1.37-1.554,1.467,1.467,0,0,1,1.37-1.554A1.467,1.467,0,0,1,15.591,13.3ZM9.319,11.746A1.467,1.467,0,0,0,7.95,13.3a1.476,1.476,0,0,0,1.37,1.554,1.467,1.467,0,0,0,1.37-1.554A1.459,1.459,0,0,0,9.319,11.746ZM23.5,2.884V28c-3.383-3.117-2.3-2.085-6.231-5.894l.712,2.59H2.753A2.821,2.821,0,0,1,0,21.812V2.884A2.821,2.821,0,0,1,2.753,0H20.747A2.821,2.821,0,0,1,23.5,2.884ZM19.673,16.156a19.36,19.36,0,0,0-1.934-8.162,6.46,6.46,0,0,0-3.773-1.47l-.188.224a8.839,8.839,0,0,1,3.344,1.778A11,11,0,0,0,7.09,8.12c-.5.238-.792.406-.792.406A8.953,8.953,0,0,1,9.83,6.692L9.7,6.524a6.46,6.46,0,0,0-3.773,1.47,19.36,19.36,0,0,0-1.934,8.162,4.838,4.838,0,0,0,4.1,2.128s.5-.63.9-1.162a4.2,4.2,0,0,1-2.35-1.652c.2.144.523.331.551.35a9.433,9.433,0,0,0,8.379.49,7.571,7.571,0,0,0,1.544-.826,4.254,4.254,0,0,1-2.431,1.666c.4.532.886,1.134.886,1.134A4.876,4.876,0,0,0,19.673,16.156Z" transform="translate(36 298)" fill="#f4f4f4"/>
      <rect id="Rectangle_2214" data-name="Rectangle 2214" width="32" height="32" transform="translate(32 296)" fill="none"/>
    </g>
  </svg>
) : (
  <svg width="40" height="40" viewBox="0 0 40 40">
    <g id="DiscordDesktop" transform="translate(-540 -540)">
      <path id="Icon_awesome-discord" data-name="Icon awesome-discord" d="M20.9,17.1a1.843,1.843,0,1,1-1.836-2A1.918,1.918,0,0,1,20.9,17.1Zm-8.406-2a2.005,2.005,0,0,0,0,4,1.918,1.918,0,0,0,1.836-2A1.906,1.906,0,0,0,12.492,15.1ZM31.5,3.708V36c-4.535-4.007-3.084-2.681-8.352-7.578l.954,3.33H3.69A3.7,3.7,0,0,1,0,28.044V3.708A3.7,3.7,0,0,1,3.69,0H27.81A3.7,3.7,0,0,1,31.5,3.708ZM26.37,20.772a24.1,24.1,0,0,0-2.592-10.494,8.9,8.9,0,0,0-5.058-1.89l-.252.288a11.979,11.979,0,0,1,4.482,2.286A15.294,15.294,0,0,0,9.5,10.44c-.666.306-1.062.522-1.062.522A12.143,12.143,0,0,1,13.176,8.6L13,8.388a8.9,8.9,0,0,0-5.058,1.89A24.1,24.1,0,0,0,5.346,20.772a6.526,6.526,0,0,0,5.49,2.736s.666-.81,1.206-1.494a5.6,5.6,0,0,1-3.15-2.124c.265.185.7.426.738.45a13.121,13.121,0,0,0,11.232.63,10.3,10.3,0,0,0,2.07-1.062,5.68,5.68,0,0,1-3.258,2.142c.54.684,1.188,1.458,1.188,1.458A6.58,6.58,0,0,0,26.37,20.772Z" transform="translate(544 542)" fill="#f4f4f4"/>
      <rect id="Rectangle_2215" data-name="Rectangle 2215" width="40" height="40" transform="translate(540 540)" fill="none"/>
    </g>
  </svg>
);

export const LinkedInIcon = () => isMobile ? (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <g id="LinkedInMobile" transform="translate(-612 -408)">
      <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M5.26,23.5H.388V7.811H5.26ZM2.822,5.671A2.835,2.835,0,1,1,5.643,2.822,2.845,2.845,0,0,1,2.822,5.671ZM23.495,23.5H18.633V15.863c0-1.82-.037-4.154-2.533-4.154-2.533,0-2.921,1.978-2.921,4.023V23.5H8.312V7.811h4.673v2.14h.068a5.119,5.119,0,0,1,4.61-2.534c4.931,0,5.837,3.247,5.837,7.464V23.5Z" transform="translate(616.25 412.25)" fill="#f4f4f4"/>
      <rect id="Rectangle_2208" data-name="Rectangle 2208" width="32" height="32" transform="translate(612 408)" fill="none"/>
    </g>
  </svg>
) : (
  <svg width="40" height="40" viewBox="0 0 40 40">
    <g id="LinkedInDesktop" transform="translate(-612 -408)">
      <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M7.051,31.5H.52V10.47H7.051ZM3.782,7.6A3.8,3.8,0,1,1,7.564,3.783,3.814,3.814,0,0,1,3.782,7.6ZM31.493,31.5H24.976V21.263c0-2.44-.049-5.569-3.4-5.569-3.4,0-3.916,2.651-3.916,5.393V31.5H11.142V10.47h6.263v2.869H17.5a6.862,6.862,0,0,1,6.179-3.4c6.609,0,7.824,4.352,7.824,10.005V31.5Z" transform="translate(616.25 412.25)" fill="#f4f4f4"/>
      <rect id="Rectangle_2208" data-name="Rectangle 2208" width="40" height="40" transform="translate(612 408)" fill="none"/>
    </g>
  </svg>
);

export const PhoneIcon = () => isMobile ? (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <g id="PhoneMobile" transform="translate(-684 -408)">
      <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M23.315,16.96l-5.25-2.25a1.125,1.125,0,0,0-1.313.323l-2.325,2.841A17.375,17.375,0,0,1,6.122,9.568L8.963,7.243A1.122,1.122,0,0,0,9.286,5.93L7.036.68A1.133,1.133,0,0,0,5.747.029L.872,1.154A1.125,1.125,0,0,0,0,2.25,21.748,21.748,0,0,0,21.75,24a1.125,1.125,0,0,0,1.1-.872l1.125-4.875a1.139,1.139,0,0,0-.657-1.294Z" transform="translate(688 412)" fill="#f4f4f4"/>
      <rect id="Rectangle_2209" data-name="Rectangle 2209" width="32" height="32" transform="translate(684 408)" fill="none"/>
    </g>
  </svg>
) : (
  <svg width="40" height="40" viewBox="0 0 40 40">
    <g id="PhoneDesktop" transform="translate(-684 -408)">
      <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M31.087,22.613l-7-3a1.5,1.5,0,0,0-1.75.431l-3.1,3.788A23.167,23.167,0,0,1,8.163,12.757l3.788-3.1a1.5,1.5,0,0,0,.431-1.75l-3-7A1.51,1.51,0,0,0,7.663.038l-6.5,1.5A1.5,1.5,0,0,0,0,3,29,29,0,0,0,29,32a1.5,1.5,0,0,0,1.463-1.163l1.5-6.5a1.518,1.518,0,0,0-.876-1.725Z" transform="translate(688 412)" fill="#f4f4f4"/>
      <rect id="Rectangle_2209" data-name="Rectangle 2209" width="40" height="40" transform="translate(684 408)" fill="none"/>
    </g>
  </svg>
);

export const MailIcon = () => isMobile ? (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <g id="MailMobile" transform="translate(-756 -408)">
      <rect id="Rectangle_2210" data-name="Rectangle 2210" width="32" height="32" transform="translate(756 408)" fill="none"/>
      <g id="Icon_feather-mail" data-name="Icon feather-mail" transform="translate(760 414)">
        <path id="Path_156" data-name="Path 156" d="M5.462,6h19.7A2.469,2.469,0,0,1,27.62,8.462V23.234A2.469,2.469,0,0,1,25.158,25.7H5.462A2.469,2.469,0,0,1,3,23.234V8.462A2.469,2.469,0,0,1,5.462,6Z" transform="translate(-3 -6)" fill="none" stroke="#f4f4f4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <path id="Path_157" data-name="Path 157" d="M27.62,9,15.31,17.617,3,9" transform="translate(-3 -6.538)" fill="none" stroke="#f4f4f4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </g>
  </svg>
) : (
  <svg width="40" height="40" viewBox="0 0 40 40">
    <g id="MailDesktop" transform="translate(-756 -408)">
      <rect id="Rectangle_2210" data-name="Rectangle 2210" width="40" height="40" transform="translate(756 408)" fill="none"/>
      <g id="Icon_feather-mail" data-name="Icon feather-mail" transform="translate(757 409.079)">
        <path id="Path_156" data-name="Path 156" d="M6.2,6H31.8A3.209,3.209,0,0,1,35,9.2V28.4a3.209,3.209,0,0,1-3.2,3.2H6.2A3.209,3.209,0,0,1,3,28.4V9.2A3.209,3.209,0,0,1,6.2,6Z" fill="none" stroke="#f4f4f4" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        <path id="Path_157" data-name="Path 157" d="M35,9,19,20.2,3,9" transform="translate(0 0.2)" fill="none" stroke="#f4f4f4" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      </g>
    </g>
  </svg>
);

export const ExternalLinkIcon = ({ color }: Color) => isMobile ? (
  <svg width="32" height="32.121" viewBox="0 0 32 32.121">
    <g id="ExternalLinkMobile" transform="translate(-266 -848.879)">
      <rect id="Rectangle_2216" data-name="Rectangle 2216" width="32" height="32" transform="translate(266 849)" fill="none"/>
      <g id="Icon_feather-external-link" data-name="Icon feather-external-link" transform="translate(263.5 846.5)">
        <path id="Path_183" data-name="Path 183" d="M27,19.5v9a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3V12a3,3,0,0,1,3-3h9" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        <path id="Path_184" data-name="Path 184" d="M22.5,4.5h9v9" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        <path id="Path_185" data-name="Path 185" d="M15,21,31.5,4.5" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      </g>
    </g>
  </svg>
) : (
  <svg width="40.121" height="40.121" viewBox="0 0 40.121 40.121">
    <g id="ExternalLinkDesktop" transform="translate(-266 -848.879)">
      <rect id="Rectangle_2216" data-name="Rectangle 2216" width="40" height="40" transform="translate(266 849)" fill="none"/>
      <g id="Icon_feather-external-link" data-name="Icon feather-external-link" transform="translate(263.5 846.5)">
        <path id="Path_183" data-name="Path 183" d="M34.509,23v12a4,4,0,0,1-4,4H8.5a4,4,0,0,1-4-4V13a4,4,0,0,1,4-4h12" transform="translate(0 1.491)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        <path id="Path_184" data-name="Path 184" d="M22.5,4.5h12v12" transform="translate(5.996 0)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        <path id="Path_185" data-name="Path 185" d="M15,26.507,37.007,4.5" transform="translate(3.493 0)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      </g>
    </g>
  </svg>
);

export const ResumeIcon = () => isMobile ? (
  <svg width="29.176" height="32.5" viewBox="0 0 29.176 32.5">
    <g id="ResumeIconMobile" transform="translate(-704 -650)">
      <path id="Icon_awesome-book" data-name="Icon awesome-book" d="M23.267,18.7V1.246A1.243,1.243,0,0,0,22.021,0H4.986A4.987,4.987,0,0,0,0,4.986V21.605a4.987,4.987,0,0,0,4.986,4.986H22.021a1.243,1.243,0,0,0,1.246-1.246v-.831a1.256,1.256,0,0,0-.462-.971,11.582,11.582,0,0,1,0-3.88A1.238,1.238,0,0,0,23.267,18.7ZM6.648,6.959a.313.313,0,0,1,.312-.312H17.97a.313.313,0,0,1,.312.312V8a.313.313,0,0,1-.312.312H6.959A.313.313,0,0,1,6.648,8Zm0,3.324a.313.313,0,0,1,.312-.312H17.97a.313.313,0,0,1,.312.312v1.039a.313.313,0,0,1-.312.312H6.959a.313.313,0,0,1-.312-.312Zm13.16,12.984H4.986a1.662,1.662,0,0,1,0-3.324H19.808A18.7,18.7,0,0,0,19.808,23.267Z" transform="translate(709.909 655.909)" fill="#de3c4b" opacity="0.2"/>
      <path id="Icon_awesome-book-2" data-name="Icon awesome-book" d="M23.267,18.7V1.246A1.243,1.243,0,0,0,22.021,0H4.986A4.987,4.987,0,0,0,0,4.986V21.605a4.987,4.987,0,0,0,4.986,4.986H22.021a1.243,1.243,0,0,0,1.246-1.246v-.831a1.256,1.256,0,0,0-.462-.971,11.582,11.582,0,0,1,0-3.88A1.238,1.238,0,0,0,23.267,18.7ZM6.648,6.959a.313.313,0,0,1,.312-.312H17.97a.313.313,0,0,1,.312.312V8a.313.313,0,0,1-.312.312H6.959A.313.313,0,0,1,6.648,8Zm0,3.324a.313.313,0,0,1,.312-.312H17.97a.313.313,0,0,1,.312.312v1.039a.313.313,0,0,1-.312.312H6.959a.313.313,0,0,1-.312-.312Zm13.16,12.984H4.986a1.662,1.662,0,0,1,0-3.324H19.808A18.7,18.7,0,0,0,19.808,23.267Z" transform="translate(704 650)" fill="#de3c4b"/>
    </g>
  </svg>
) : (
  <svg width="39.5" height="44" viewBox="0 0 39.5 44">
    <g id="ResumeIconDesktop" transform="translate(-704 -650)">
      <path id="Icon_awesome-book" data-name="Icon awesome-book" d="M31.5,25.313V1.688A1.683,1.683,0,0,0,29.813,0H6.75A6.752,6.752,0,0,0,0,6.75v22.5A6.752,6.752,0,0,0,6.75,36H29.813A1.683,1.683,0,0,0,31.5,34.313V33.188a1.7,1.7,0,0,0-.626-1.315,15.68,15.68,0,0,1,0-5.252A1.676,1.676,0,0,0,31.5,25.313ZM9,9.422A.423.423,0,0,1,9.422,9H24.328a.423.423,0,0,1,.422.422v1.406a.423.423,0,0,1-.422.422H9.422A.423.423,0,0,1,9,10.828Zm0,4.5a.423.423,0,0,1,.422-.422H24.328a.423.423,0,0,1,.422.422v1.406a.423.423,0,0,1-.422.422H9.422A.423.423,0,0,1,9,15.328ZM26.817,31.5H6.75a2.25,2.25,0,0,1,0-4.5H26.817A25.313,25.313,0,0,0,26.817,31.5Z" transform="translate(712 658)" fill="#de3c4b" opacity="0.2"/>
      <path id="Icon_awesome-book-2" data-name="Icon awesome-book" d="M31.5,25.313V1.688A1.683,1.683,0,0,0,29.813,0H6.75A6.752,6.752,0,0,0,0,6.75v22.5A6.752,6.752,0,0,0,6.75,36H29.813A1.683,1.683,0,0,0,31.5,34.313V33.188a1.7,1.7,0,0,0-.626-1.315,15.68,15.68,0,0,1,0-5.252A1.676,1.676,0,0,0,31.5,25.313ZM9,9.422A.423.423,0,0,1,9.422,9H24.328a.423.423,0,0,1,.422.422v1.406a.423.423,0,0,1-.422.422H9.422A.423.423,0,0,1,9,10.828Zm0,4.5a.423.423,0,0,1,.422-.422H24.328a.423.423,0,0,1,.422.422v1.406a.423.423,0,0,1-.422.422H9.422A.423.423,0,0,1,9,15.328ZM26.817,31.5H6.75a2.25,2.25,0,0,1,0-4.5H26.817A25.313,25.313,0,0,0,26.817,31.5Z" transform="translate(704 650)" fill="#de3c4b"/>
    </g>
  </svg>
);